import { Typography } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { BgOptions } from '../types/HeroBanner';

const { Title } = Typography;

interface StyledFlexProps {
  direction?: React.CSSProperties['flexDirection'];
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  position?: React.CSSProperties['position'];
  gap?: string | 0;
  ph?: string | 0;
  pv?: string | 0;
}

export const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  flex-wrap: no-wrap;

  ${({
    direction = 'row',
    justify = 'flex-start',
    align = 'flex-start',
    position = 'static',
    ph = 0,
    pv = 0,
    gap = 0,
  }) => `
    position: ${position};
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
    gap: ${gap};
    padding: ${pv} ${ph};
  }
  `};
`;

const applyBg = (bg: BgOptions, theme: any) => {
  switch (bg) {
    case BgOptions.DARK:
      return theme.palette.sectionBackgroundPrimary;
      break;
    case BgOptions.LIGHT:
      return theme.palette.sectionBackgroundSecondary;
      break;
    default:
      return theme.palette.white;
      break;
  }
};

export const StyledSection = styled.section<
  Pick<
    ISection,
    'bg' | 'imgPath' | 'direction' | 'justify' | 'align' | 'position' | 'gap'
  >
>`
  width: auto;
  display: flex;

  ${({ theme, bg, imgPath, direction, justify, align, position, gap }) => `
    position: ${position};
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
    gap: ${gap};
    margin-bottom:${theme.spacing.medium};
    
    padding: ${theme.spacing.superLarge2} ${theme.spacing.veryLarge};
    @media (max-width: ${theme.breakpoints.tablet}){
      padding: ${theme.spacing.large};
    }
    ${
      imgPath
        ? `
            background: linear-gradient(
              ${
                bg === BgOptions.DARK
                  ? ` rgba(0, 0, 0, 0.4),
                  rgba(0, 0, 0, 0.4)`
                  : ` rgba(255, 255, 255, 0.33),
                  rgba(255, 255, 255, 0.33)`
              }
              ),
              url(${imgPath});
            height: 600px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          `
        : `
          background-color: ${
            bg ? applyBg(bg, theme) : theme.palette.sectionBackground
          };
        `
    }

    }
    ${
      justify === 'space-evenly'
        ? `
    @media (min-width: ${theme.breakpoints.extraWide}){
      justify-content: center;
      gap: ${theme.spacing.extraLarge};
    }`
        : `
    @media (min-width: ${theme.breakpoints.extraWide}){
      padding: ${theme.spacing.superLarge2} 0 ${theme.spacing.superLarge2} 0;
    }`
    }
  `};
`;

const SectionHeader = styled(Title)<Pick<ISection, 'bg'>>`
  ${({ theme, bg }) => `
    &&& {
      margin-bottom: ${theme.spacing.veryLarge};
      font-size: ${theme.fontSizes.large};
      color: ${
        bg === BgOptions.DARK
          ? `${theme.palette.white}`
          : `${theme.palette.black}`
      };
    }
    @media (max-width: ${theme.breakpoints.tablet}){
      &&& {
        font-size: ${theme.fontSizes.mediumLarge};
      }
    }
    @media (max-width: ${theme.breakpoints.mobile}){
      &&& {
        font-size: ${theme.fontSizes.medium};
      }
    }
  `}
`;

interface ISection {
  title?: string;
  sectionId?: string;
  bg?: BgOptions;
  imgPath?: string;
  children?: any;
  alignLeft?: boolean;
  direction?: React.CSSProperties['flexDirection'];
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  position?: React.CSSProperties['position'];
  gap?: React.CSSProperties['gap'];
}

const Section: FC<ISection> = ({
  title,
  sectionId,
  bg,
  children,
  imgPath = '',
  direction = 'row',
  justify = 'flex-start',
  align = 'flex-start',
  position = 'static',
  gap,
}) => {
  return (
    <StyledSection
      id={sectionId}
      bg={bg}
      imgPath={imgPath}
      direction={direction}
      justify={justify}
      align={align}
      position={position}
      gap={gap}>
      {title && (
        <SectionHeader level={2} bg={bg}>
          {title}
        </SectionHeader>
      )}
      {children && children}
    </StyledSection>
  );
};

export default Section;
