import coverImage from '../assets/images/coverImage_GLOBAL.webp';
import coverImage_EU from '../assets/images/EU/coverImage-eu-min.webp';
import coverImage_EA from '../assets/images/EA/coverImage-ea-min.webp';
import coverImage_PE from '../assets/images/PE/coverImage-pe.jpg';
import coverImage_CA from '../assets/images/CA/coverImage_CA.webp';

import eligibilityImage from '../assets/images/heroImage_GLOBAL.webp';
import eligibilityImage_fall from '../assets/images/heroImage_GLOBAL.jpg';

import eligibilityImage_EU from '../assets/images/EU/heroImage-eu-min.webp';
import eligibilityImage_EU_fall from '../assets/images/EU/heroImage-eu-min.jpg';

import eligibilityImage_EA from '../assets/images/EA/heroImage-ea-min.webp';
import eligibilityImage_EA_fall from '../assets/images/EA/heroImage-ea-min.jpg';

import eligibilityImage_CA from '../assets/images/CA/heroImage_CA.webp';
import eligibilityImage_CA_fall from '../assets/images/CA/heroImage_CA.jpg';

import eligibilityImage_BR from '../assets/images/BR/heroImage_BR.webp';

import eligibilityImage_ZA from '../assets/images/ZA/heroImage_ZA.webp';
import eligibilityImage_ZA_fall from '../assets/images/ZA/heroImage_ZA_fall.jpg';

import eligibilityImage_ME from '../assets/images/ME/heroImage-me-min.webp';
import eligibilityImage_ME_fall from '../assets/images/ME/heroImage-me-min.jpg';
import { BgOptions } from '../types/HeroBanner';

const formatImagesObj = (
  cover: string,
  eligibility: string,
  eligibilityFallback: string,
  bg?: BgOptions,
) => ({
  coverImg: cover,
  eligibilityImg: eligibility,
  eligibilityImg_fall: eligibilityFallback,
  bg: bg || BgOptions.LIGHT,
});

export const determineImages = (language: string) => {
  switch (language) {
    case 'ko-KR':
      return formatImagesObj(
        coverImage_EA,
        eligibilityImage_EA,
        eligibilityImage_EA_fall,
      );
    case 'es-PE':
      return formatImagesObj(
        coverImage_PE,
        eligibilityImage_EU,
        eligibilityImage_EU_fall,
        BgOptions.DARK,
      );
    case 'pt-BR':
      return formatImagesObj(
        coverImage,
        eligibilityImage_BR,
        eligibilityImage_fall,
        BgOptions.DARK,
      );
    case 'en-GB':
    case 'en-IE':
    case 'bg-BG':
    case 'el-GR':
    case 'ro-RO':
    case 'hu-HU':
    case 'sk-SK':
    case 'nl-BE':
    case 'fr-BE':
      return formatImagesObj(
        coverImage_EU,
        eligibilityImage_EU,
        eligibilityImage_EU_fall,
      );
    case 'tr-TR':
      return formatImagesObj(
        coverImage_EU,
        eligibilityImage_ME,
        eligibilityImage_ME_fall,
      );
    case 'en-CA':
      return formatImagesObj(
        coverImage_CA,
        eligibilityImage_CA,
        eligibilityImage_CA_fall,
      );
    case 'fr-CA':
      return formatImagesObj(
        coverImage_CA,
        eligibilityImage_CA,
        eligibilityImage_CA_fall,
      );
    case 'af-ZA':
      return formatImagesObj(
        coverImage,
        eligibilityImage_ZA,
        eligibilityImage_ZA_fall,
      );
    case 'en-ZA':
      return formatImagesObj(
        coverImage,
        eligibilityImage_ZA,
        eligibilityImage_ZA_fall,
      );
    default:
      return formatImagesObj(
        coverImage,
        eligibilityImage,
        eligibilityImage_fall,
      );
  }
};
