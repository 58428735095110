import 'react-phone-number-input/style.css';
import PhoneInput, { Country } from 'react-phone-number-input';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type CountrySelectProps = {
  value: string | undefined;
  onChange: (val: string | undefined) => void;
};

const StyledSelect = styled.select`
  width: max-content;
  margin-right: 1px;
  height: 2rem;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

const CountrySelect = ({ value, onChange, ...rest }: CountrySelectProps) => {
  const countryAndCode = getCountries().map((country) => {
    return {
      country,
      callingCode: getCountryCallingCode(country),
    };
  });
  return (
    <StyledSelect
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}>
      {countryAndCode.map((countryCode) => (
        <option key={countryCode.country} value={countryCode.country}>
          {countryCode.country} +{countryCode.callingCode}
        </option>
      ))}
    </StyledSelect>
  );
};

export const CustomPhoneNumberWidget = (props: any) => {
  const { i18n } = useTranslation();
  const { onChange } = props;

  return (
    <PhoneInput
      id="root_phone"
      defaultCountry={i18n.language.slice(3, 5).toUpperCase() as Country}
      data-testid="root_phone"
      countrySelectComponent={CountrySelect}
      onChange={onChange}
    />
  );
};
