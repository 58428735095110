import { Space } from 'antd';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { StyledParagraph, StyledTitle } from '../../styles/typography';
import { HeaderWrapper } from '../HeaderAndFooter/Header';
import { NavLink } from '../Link/Link';

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => `
    gap: ${theme.spacing.superLarge};
    @media (max-width: ${theme.breakpoints.mobile}){
        flex-direction: column-reverse;
        gap: ${theme.spacing.mediumLarge};
    }
`};
`;

const StyledDivider = styled(HeaderWrapper)`
  justify-content: flex-start;
  padding: 0;
  margin-left: 0;

  ${({ theme }) => `
    margin-bottom: ${theme.spacing.veryLarge};

    @media (max-width: ${theme.breakpoints.mobile}){
        margin-bottom: ${theme.spacing.large};
        flex-direction: row;
    }
  `};
`;

const Tab = styled(NavLink)<{ active: boolean }>`
  border-bottom: 5px solid transparent;
  background-color: transparent;
  margin: 0;
  height: 100%;

  ${({ theme, active }) => `
    padding: ${theme.spacing.large} ${theme.spacing.medium};
    margin-right: ${theme.spacing.medium};
    font-size: ${theme.fontSizes.medium};
    ${
      active &&
      `border-bottom: 5px solid ${theme.palette.primary}; border-radius: 0px;`
    }
  `}
`;

export const InfoParagraph = styled(StyledParagraph)`
  ${({ theme }) => `
  &&& {
    color: ${theme.palette.default};
    font-size: ${theme.fontSizes.mediumSmall};
    &.ref-num{
      font-size: ${theme.fontSizes.medium};
      width: 20vw;
    }
    strong{
      color: ${theme.palette.primary};
      font-size: ${theme.fontSizes.mediumLarge};
    }
    @media (max-width: ${theme.breakpoints.mobile}){
      &.ref-num{
        width: 100%;
      }
    }
    `}
  }
`;
export interface IDividerNavProps {
  active: string;
  tabItems?: Array<{ id: number; name: string }>;
  changeTab: (arg0: string) => void;
}

const DividerNav: FC<IDividerNavProps> = ({ active, tabItems, changeTab }) => {
  return (
    <StyledDivider>
      {tabItems &&
        tabItems.map((item) => (
          <Tab
            data-mixpanelid={`Navigation_Tab_${item.name}`}
            active={active === item.name}
            key={item.id}
            onClick={() => changeTab(item.name)}>
            {item.name}
          </Tab>
        ))}
    </StyledDivider>
  );
};

interface TrialInfo {
  id: number;
  name: string;
  information: string;
  refNum: string;
}

interface ITrialInfoProps {
  trials: Array<TrialInfo>;
  children?: any;
}

const TrialInfo: FC<ITrialInfoProps> = ({ trials }) => {
  const [trialName, setTrialName] = useState(trials[0].name);

  const displayTrial =
    trials && trials.find((trial) => trial.name === trialName);

  const tabItems =
    trials &&
    trials.map((trial) => {
      const { id, name } = trial;
      return { id, name };
    });

  return (
    <>
      <DividerNav
        active={trialName}
        tabItems={tabItems}
        changeTab={(name) => setTrialName(name)}
      />
      <div>
        <StyledTitle>{t('trialInformation.title')}</StyledTitle>
        <InfoContainer>
          <InfoParagraph alignLeft width={60}>
            {displayTrial && displayTrial.information}
          </InfoParagraph>
          <Space direction="vertical">
            <InfoParagraph>{t('trialInformation.refNum')}</InfoParagraph>
            <InfoParagraph strong>
              {displayTrial && displayTrial.refNum}
            </InfoParagraph>
          </Space>
        </InfoContainer>
      </div>
    </>
  );
};

export default TrialInfo;
