import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { toLatLng } from '../utils/toLatLng';
import {
  GeocoderRequest,
  IFormState,
  INamedLocation,
  LatLngLiteral,
} from './types';
import { submitReferral } from './api';

export const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: any) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addEventListener('change', updateTarget, { passive: true });

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeEventListener('change', updateTarget);
  }, [updateTarget, width]);

  return targetReached;
};

/**
 * Return the short and long locale codes
 */
export const useLocaleCode = () => {
  const { i18n } = useTranslation();
  return useMemo(() => {
    return [i18n.language.substring(0, 2), i18n.language];
  }, [i18n.language]);
};

export const getCurrentPosition = (
  options: PositionOptions,
): Promise<LatLngLiteral> =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) =>
          resolve(
            toLatLng(position.coords.latitude, position.coords.longitude),
          ),
        (err) => reject(err),
        options,
      );
    } else {
      reject(new Error('geolocation unavailable'));
    }
  });

function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export const useGeolocationPermissionState = () => {
  const [permissionState, setPermissionState] = useState<PermissionState>();
  const isMounted = useIsMounted();
  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          // eslint-disable-next-line no-console
          console.info(
            'geolocation permission state changed to',
            permissionStatus.state,
          );
          // eslint-disable-next-line no-param-reassign
          permissionStatus.onchange = (evt) => {
            if (isMounted()) {
              setPermissionState((evt.target as PermissionStatus).state);
            }
          };
        });
    }

    return () => {
      if (navigator.permissions) {
        navigator.permissions
          .query({ name: 'geolocation' })
          .then((permissionStatus) => {
            // eslint-disable-next-line no-param-reassign
            permissionStatus.onchange = null;
          });
      }
    };
  }, [isMounted]);
  return permissionState;
};

export const useGeolocationCurrentPosition = () => {
  const permissionState = useGeolocationPermissionState();
  const [location, setLocation] = useState<INamedLocation | null>();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);

      try {
        const latlng = await getCurrentPosition({
          maximumAge: 300000,
          enableHighAccuracy: false,
        });
        const geocoder = new google.maps.Geocoder();
        const geocoderResponse = await geocoder.geocode({
          location: latlng,
        } as GeocoderRequest);

        let formattedAddress = '';
        if (geocoderResponse && geocoderResponse.results[0]) {
          const filteredAddress = geocoderResponse.results.filter((value) =>
            value.types.includes('postal_code'),
          );
          if (filteredAddress.length) {
            formattedAddress = filteredAddress[0].formatted_address;
          } else {
            formattedAddress = geocoderResponse.results[0].formatted_address;
          }
        }
        if (isMounted()) {
          setLocation({ latlng, formattedAddress });
        }
        // Component might no longer be mounted
      } catch (e) {
        if (isMounted()) {
          setLocation(null);
        }
      }

      setIsLoading(false);
    };

    runEffect();
  }, [isMounted, permissionState]);
  return { location, isLoading };
};

export const useSubmitReferral = () => {
  const { mutate, isLoading, mutateAsync } = useMutation<
    AxiosResponse<any>,
    AxiosError,
    IFormState
  >((formData: IFormState) => submitReferral(formData, formData.siteId));

  return {
    submitReferralAsync: mutateAsync,
    submitReferral: mutate,
    isSubmitReferralLoading: isLoading,
  };
};
