import i18n from './i18n';

export const dateConvert = (
  date: Date,
  options: Partial<Intl.DateTimeFormatOptions> = {
    year: 'numeric',
    month: 'long',
  },
): string => {
  const dateTimeFormat = new Intl.DateTimeFormat(i18n.language, options);
  const newDate = dateTimeFormat.format(date);
  return newDate;
};
