import { LatLngLiteral } from '@reifyhealth/microsite-components';

export const toLatLng = (lat: number, lng: number) =>
  ({ lat, lng } as LatLngLiteral);

// Curled from https://stackoverflow.com/a/21623206
export const computeDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
  distanceUnit: 'miles' | 'kilometers',
) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const a =
    0.5 -
    Math.cos((lat2 - lat1) * p) / 2 +
    (Math.cos(lat1 * p) *
      Math.cos(lat2 * p) *
      (1 - Math.cos((lng2 - lng1) * p))) /
      2;

  if (distanceUnit === 'kilometers') return 12742 * Math.asin(Math.sqrt(a));

  return 12742 * Math.asin(Math.sqrt(a)) * 0.621371; // 12742 = 2 * R; R = 6371 km
};

export const formatAddress = (
  city?: string,
  state?: string,
  postalCode?: string,
) => {
  let address = city;
  if (state) {
    address += city ? `, ${state}` : state;
  }
  if (postalCode) {
    if (state) {
      address += ` ${postalCode}`;
    } else if (city) {
      address += `, ${postalCode}`;
    } else {
      address += postalCode;
    }
  }
  return address;
};
