import { ReactNode } from 'react';

export enum ETrialStatus {
  'DONE' = 'done',
  'RECRUITING' = 'recruiting',
  'NOT_RECRUITING' = 'not recruiting',
  'TBA' = 'to be announced',
}

export enum ETrialStatusColor {
  'DONE' = '#ff7575',
  'RECRUITING' = '#52C41A',
  'NOT_RECRUITING' = '#ea4d4d',
  'TBA' = '#bac',
}

export const trialStatusMap = new Map<ETrialStatus, ETrialStatusColor>([
  [ETrialStatus.DONE, ETrialStatusColor.DONE],
  [ETrialStatus.NOT_RECRUITING, ETrialStatusColor.NOT_RECRUITING],
  [ETrialStatus.RECRUITING, ETrialStatusColor.RECRUITING],
  [ETrialStatus.TBA, ETrialStatusColor.TBA],
]);

type ReactNodeWithoutObject = Omit<ReactNode, 'ReactFragment'>;

export type WithChildren<T = Record<string, unknown>> = T & {
  children?: ReactNodeWithoutObject;
};

export type PersonalInfoFormData = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};
