import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Caret } from '../icons/SVGs';
import { trackEvent } from '../../core/analytics';

const { Title } = Typography;

const AccordionContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 19px;
  ${({ theme }) => `
    background-color: ${theme.palette.bgPrimary};
    margin-bottom:${theme.spacing.large} ;
    padding: ${theme.spacing.veryLarge};

    @media (min-width: ${theme.breakpoints.extraWide}){
      width: 60%;
    }
    @media (max-width: ${theme.breakpoints.tablet}) {
      width: 100%;
      padding: ${theme.spacing.medium} ${theme.spacing.large};
    }
    @media (max-width: ${theme.breakpoints.mobile}) {
      padding: ${theme.spacing.medium} ${theme.spacing.large};
    }
  `}
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const StyledAccordionTitle = styled(Title)`
  ${({ theme }) => `
    &&& {
      font-size: ${theme.fontSizes.mediumLarge};
      color: ${theme.palette.primary};
      margin: 0;

      @media (max-width: ${theme.breakpoints.tablet}){
        font-size: ${theme.fontSizes.medium};
      }
      @media (max-width: ${theme.breakpoints.mobile}){
        font-size: ${theme.fontSizes.mediumSmall};
      }
    }
  `}
`;
interface IAccordionDescription {
  open: boolean;
}

const AccordionDescription = styled.div<IAccordionDescription>`
  height: auto;
  overflow: auto;
  padding: 0;
  transition: all 0.25s linear;
  width: 95%;
  ${({ theme }) => `
    & p {
      color: ${theme.palette.default};
      font-size: ${theme.fontSizes.mediumSmall};
      margin-top: ${theme.spacing.mediumLarge};
      line-height: ${theme.fonts.lineHeightLarge};
    }
`};
`;

export const StyledCaret = styled.div<IAccordionDescription>`
  transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.25s linear;
`;

interface IAccordionProps {
  title: string;
  mixpanelId: string;
  defaultOpen?: boolean;
}

const Accordion: FC<IAccordionProps> = ({
  title,
  mixpanelId,
  defaultOpen = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <AccordionContainer data-mixpanelid={`Toggle_${mixpanelId}`}>
      <AccordionHeader
        onClick={() => {
          setIsOpen((current) => {
            trackEvent('toggle-faq', {
              open: !current,
              id: mixpanelId,
            });
            return !current;
          });
        }}>
        <StyledAccordionTitle>{title}</StyledAccordionTitle>
        <StyledCaret open={isOpen}>
          <Caret />
        </StyledCaret>
      </AccordionHeader>
      {isOpen && (
        <AccordionDescription open={isOpen}>{children}</AccordionDescription>
      )}
    </AccordionContainer>
  );
};

export default Accordion;
