import React, { FC } from 'react';
import { customTheme } from '../../theme';
import { StyledParagraph } from '../../styles/typography';
import { StyledFlex } from '../Section';

interface EligibilitySectionProps {
  text: string;
  children?: any;
}

const EligibilitySection: FC<EligibilitySectionProps> = ({
  text,
  children,
}) => {
  return (
    <StyledFlex
      direction="column"
      align="center"
      gap={customTheme.spacing.medium}>
      <StyledFlex
        direction="column"
        justify="space-evenly"
        gap={customTheme.spacing.mediumLarge}>
        {children}
      </StyledFlex>
      <StyledParagraph
        weight={customTheme.fonts.fontWeight500}
        size={customTheme.fontSizes.mediumSmall}
        width={450}>
        {text}
      </StyledParagraph>
    </StyledFlex>
  );
};

export default EligibilitySection;
