import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)<IButtonProps>`
  font-weight: bold;
  border-color: transparent;
  cursor: pointer;
  height: fit-content;

  ${({ theme, $fullWidth }) => `
    &&&{
      color: ${theme.palette.buttonTextPrimary};
      background-color: ${theme.palette.buttonPrimary};
      border-radius: ${theme.spacing.large};
      margin-right: ${theme.spacing.medium};
      width: ${$fullWidth ? '100%' : 'auto'};
      padding: ${theme.spacing.mediumLarge} ${theme.spacing.veryLarge};
    }
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 0;
    
    :hover,
    :focus,
    :active {
      cursor: pointer;
      background-color: ${theme.palette.buttonPrimaryLight};
      color: ${theme.palette.buttonTextPrimary};
      border-color: transparent;
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
      &&&{ 
        padding: ${theme.spacing.mediumLarge} ${theme.spacing.veryLarge};
      }
    }

    @media (max-width: ${theme.breakpoints.mobile}) {
      &&& { 
        height: fit-content;
        max-width: 100%;
        white-space: pre-line;
        word-break: break-word !important;
        text-align: left;
        line-height: normal;
        padding: ${theme.spacing.medium} ${theme.spacing.large};
      }
    }
  `};
`;
interface IButtonProps extends ButtonProps {
  children: any;
  $fullWidth?: boolean;
}

const BaseButton: FC<IButtonProps> = ({
  children,
  $fullWidth = false,
  ...props
}) => (
  <StyledButton $fullWidth={$fullWidth} {...props}>
    {children}
  </StyledButton>
);

export default BaseButton;
