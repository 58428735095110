import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  flex-grow: 1;
  position: relative;

  ${({ theme }) => `
  @media (min-width: ${theme.breakpoints.extraWide}) { 
    max-width: 1615px;
  }
`}
`;
