import React, { useCallback, useEffect, useRef } from 'react';
import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';

import { V1Site, LatLngLiteral } from '@reifyhealth/microsite-components';

export type ReifyGoogleMapsProps = {
  /**
   * List of sites to show on the left side of the map
   */
  mapData: V1Site[];
  /**
   * User's current location
   */
  currentLocation: LatLngLiteral;
  /**
   * Callback function to execute on marker click
   */
  onMarkerClicked: (evt: google.maps.MapMouseEvent, site: V1Site) => void;
  /**
   * Callback function to execute on map load
   */
  onMapLoaded: (map: any) => void;
  /**
   * Callback function to execute on map unmount
   */
  onMapUnmount: () => void;
  /**
   * Current selected item id
   */
  selectedItemId?: string;
  /**
   * Custom className(s) to attach
   */
  className?: string | string[];
} & Partial<GoogleMapProps>;

export type CustomMarkerProps = Pick<
  ReifyGoogleMapsProps,
  'onMarkerClicked'
> & {
  /**
   * Site
   */
  site: V1Site;
  /**
   * Cluster for overlapping markers
   */
  clusterer: any;
  /**
   * Is marker selected
   */
  isSelected: boolean;
  /**
   * Map ref
   */
  mapRef: React.MutableRefObject<google.maps.Map | null>;
};

const options = {
  minimumClusterSize: 3,
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};
const CustomMarker = ({
  site,
  onMarkerClicked,
  clusterer,
  isSelected,
  mapRef,
}: CustomMarkerProps) => {
  const markerRef = useRef<google.maps.Marker | null>();

  const onMarkerLoad = useCallback(function onLoadMarker(
    marker: google.maps.Marker | null,
  ) {
    markerRef.current = marker;
  },
  []);

  useEffect(() => {
    let timer: any = null;
    if (isSelected) {
      if (markerRef.current) {
        const pos = markerRef.current.getPosition();
        mapRef.current?.panTo(pos as google.maps.LatLng);
        markerRef.current.setAnimation(google.maps.Animation.BOUNCE);
        timer = setTimeout(() => {
          markerRef.current?.setAnimation(null);
        }, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isSelected, mapRef]);

  return (
    <div>
      <Marker
        key={site.siteId}
        title="Map marker for site"
        position={{ lat: site.latitude, lng: site.longitude }}
        onClick={(evt: google.maps.MapMouseEvent) => {
          onMarkerClicked(evt, site);
        }}
        icon={{ url: '/map_marker_1.svg' }}
        onLoad={onMarkerLoad}
        clusterer={clusterer}
      />
    </div>
  );
};
const ReifyGoogleMaps = ({
  mapData,
  currentLocation,
  onMarkerClicked,
  onMapLoaded,
  onMapUnmount,
  selectedItemId,
  className,
}: ReifyGoogleMapsProps) => {
  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoaded = useCallback(
    function onLoadMap(mapInstance: google.maps.Map | null) {
      mapRef.current = mapInstance;
      onMapLoaded(mapInstance);
    },
    [onMapLoaded],
  );
  return (
    <div
      aria-live="polite"
      role="application"
      className={`msc-site-lookup__google-map ${
        typeof className === 'string' ? className : className?.join(' ')
      }`}
      style={{ height: '100%', width: '100%' }}>
      <GoogleMap
        mapContainerStyle={{ height: '100%', width: '100%' }}
        center={currentLocation}
        clickableIcons
        onLoad={onLoaded}
        onUnmount={onMapUnmount}
        options={{ mapTypeControl: false, streetViewControl: false }}>
        <Marker
          title="Map marker for starting address"
          icon={{ url: '/map_marker_home.svg' }}
          position={currentLocation}
        />
        <MarkerClusterer options={options}>
          {(clusterer) => (
            <>
              {mapData.map((site) => (
                <CustomMarker
                  key={site.siteId}
                  isSelected={selectedItemId === site.siteId}
                  onMarkerClicked={onMarkerClicked}
                  site={site}
                  clusterer={clusterer}
                  mapRef={mapRef}
                />
              ))}
            </>
          )}
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

export default React.memo(ReifyGoogleMaps);
