import axios from 'axios';
import { IFormState } from './types';

const API_HOST_URL = process.env.NEXT_PUBLIC_STUDY_TEAM_API_HOST;

const api = axios.create({
  baseURL: API_HOST_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const submitReferral = async (formData: IFormState, siteId: string) =>
  api.post(`/ms/s/${siteId}`, formData);
