import React from 'react';
import styled from 'styled-components';
import { WithChildren } from '../../core/types';
import { CloseButton } from '../buttons/CloseButton';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(229 229 229 / 80%);
`;
const ModalCard = styled.div`
  width: 55%;
  background: #f5f5f5;
  overflow: scroll;
  margin: 1rem auto;
  padding: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  @media screen and (max-width: 737px) {
    width: 90%;
  }
`;
const ModalCardHead = styled.header`
  background: #f0f0f0;
  box-shadow: 0 1px 0 #dadada;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #dbdbdb;
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
`;
const ModalHeaderTitle = styled.p`
  font-weight: 700;
  margin: 1rem 0 1rem 1.5rem;
  color: #363636;
  flex-grow: 1;
  font-size: 1.5rem;
  line-height: 1;
}
`;
type ModalProps = WithChildren<{
  isVisible: boolean;
}>;

interface IModalHeaderProps {
  onClose: () => void;
  title: string;
}

export const ModalHeader = ({ onClose, title }: IModalHeaderProps) => (
  <ModalCardHead>
    <ModalHeaderTitle>{title}</ModalHeaderTitle>
    <CloseButton onClick={onClose}>X</CloseButton>
  </ModalCardHead>
);

export const Modal = ({ isVisible, children }: ModalProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {isVisible && (
      <ModalWrapper>
        <ModalCard>{children}</ModalCard>
      </ModalWrapper>
    )}
  </>
);
