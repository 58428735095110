/* eslint-disable prefer-template */

import {
  EncounterItem,
  IconvertFormDataToReferralRequestBodyFormat,
  QuestionData,
  TelecomItem,
  V1ReferralBody,
} from '../core/types';

export const convertFormDataToReferralRequestBodyFormat = (
  formDataObj: IconvertFormDataToReferralRequestBodyFormat,
) => {
  const {
    formData,
    preScreenQuestionsData,
    consentText,
    sponsorSiteId,
    trialId,
    campaignCode,
    subjectId,
  } = formDataObj;
  const currentDate = new Date().toISOString();

  const encounter: EncounterItem[] = preScreenQuestionsData.map(
    (item: QuestionData, index: number) => ({
      source: 'web',
      response: {
        item: [
          {
            // change this to item.uuid once we change the atlas report to allow that
            linkId: String(index),
            text: item.question,
            answer: [
              {
                valueString: item.answer,
              },
            ],
          },
        ],
      },
    }),
  );

  const telecom: TelecomItem[] = [];
  if (formData.phone) {
    telecom.push({ use: 'home', system: 'phone', value: formData.phone });
  }
  if (formData.email) {
    telecom.push({ use: 'home', system: 'email', value: formData.email });
  }

  const referralBody: V1ReferralBody = {
    referral: {
      primaryTrial: {
        id: trialId,
        sponsorSiteId,
      },
      subjectId,
      subjectCreatedAt: currentDate,
      name: [
        {
          family: formData.lastName,
          given: [formData.firstName],
          use: 'official',
        },
      ],
      consent: {
        verification: {
          verificationDate: currentDate,
          verified: true,
          verifiedWith: {
            name: 'self',
            relationship: 'ONESELF',
          },
        },
        text: consentText,
      },
      encounter,
      telecom,
      campaignCode,
    },
  };

  return referralBody;
};
