const {
  REACT_APP_ENV,
  REACT_APP_TRIAL_ID,
  REACT_APP_MICROSITE_ID,
  REACT_APP_I18N_PSEUDO_ENABLED,
  REACT_APP_MIXPANEL_ID,
  REACT_APP_COOKIEBOT_US_ID,
  REACT_APP_COOKIEBOT_NON_US_ID,
  REACT_APP_SUPPORTED_LANGUAGES,
} = process.env;

const config = {
  env: REACT_APP_ENV as string,
  mixpanelId: REACT_APP_MIXPANEL_ID as string,
  cookieBotUsId: REACT_APP_COOKIEBOT_US_ID as string,
  cookieBotNonUsId: REACT_APP_COOKIEBOT_NON_US_ID as string,
  micrositeId: REACT_APP_MICROSITE_ID as string,
  trialId: REACT_APP_TRIAL_ID as string,
  isPseudoEnabled: REACT_APP_I18N_PSEUDO_ENABLED === 'true',
  supportedLanguages: REACT_APP_SUPPORTED_LANGUAGES?.split(', ') || ['en-US'],
};

export default config;
