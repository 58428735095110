import { createGlobalStyle } from 'styled-components';
import { customTheme } from './theme';

const GlobalStyle = createGlobalStyle`
    .ant-btn{
        min-width: 6rem;
        height: 2.5rem;
        margin-right: 1rem;
        color: #6E6E6E;
        font-weight: bold;
        background-color: #D2D2D2;
        border-radius: 6px;
        border: none;
        :hover{
            background-color: #D2D2D2;
            color: #6E6E6E;
        }
    }
    .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus{
        background-color: #3EA7AD;
        color: white;
    }    
    .ant-select-selector {
        width: 100%;
        text-align: center !important;
        border-radius: 0.625rem !important;
        height: 3rem !important;
        align-items: center !important;
        input{
           margin-top: 0.5rem !important;
        }
    }
    .dropdown-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #4FA829;
    }
    .ant-select-item-group {
        font-weight: bold;
        font-size: 14px;
        color: black;
    }

    .ant-form-item-label,
    .ant-form-item-required,
    .ant-checkbox-wrapper {
        height: fit-content !important;
        font-weight: bold;
    }
    .ant-checkbox-wrapper > span {
        @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
    .ant-form-item-required::before {
        content: none !important;
    }
    .ant-form-item-has-error.ant-form-item-has-feedback
    .ant-form-item-children-icon {
        display: none !important;
    }
    #emailLink {
        all: unset;
        text-decoration: underline;
        word-wrap: break-word;
    }

    #root_phone {
        padding: 4px 11px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
      }

    #pager-footer-wrapper > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: #f0f0f0;
        padding: 20px;
      }
    #pager-footer-wrapper > div p{
        color: ${customTheme.palette.consentText};
        max-width: none;
    }
    #pager-footer-wrapper > div > div{
        align-items: center;
    } 
    #site-list-container li a{
        color: ${customTheme.palette.primary} !important;
    }
    #site-list-container .ant-col{ 
        div, span {
        color: ${customTheme.palette.primary} !important;
        }
    }
    #site-list-container .ant-btn-primary{
        width: fit-content;
        height: 2.5rem;
        cursor: pointer;
        background-color: ${customTheme.palette.buttonPrimary};
        border-radius: ${customTheme.spacing.large};
        border: none;   
        padding: 0 ${customTheme.spacing.medium};
        font-weight: 400;
        color: white !important;
        span{
            color:white !important;
        }
    } 
    
    .msc-site-list-item__wrapper{
        border-left-color: ${customTheme.palette.primary};
        
        & button {
            font-size: 14px;
            padding: ${customTheme.spacing.small} ${customTheme.spacing.medium} !important;
            height: fit-content !important;
            max-width: 100%;
            white-space: normal;
        }
    }
    
    
    @media screen and (max-width: 1024px) {
        .ant-form-item-label [for='root_tellTheTeam'] {
            padding-bottom: 2rem !important;
        }
        #pager-footer-wrapper > div {
            flex-direction: column;
        }
        
        #pager-footer-wrapper > div > div {
            flex-wrap: wrap;
        }
        
        #pager-footer-wrapper > div > div > div {
            margin-bottom: 0.5rem;
        }
    }

    @media screen and (max-width: 1450px) {
        .ant-form-item-label [for='root_tellTheTeam'] {
            padding-bottom: 2rem !important;
        }
    }

    @media screen and (max-width: 820px) {
        .ant-form-item-label [for='root_tellTheTeam'] {
            padding-bottom: 2rem !important;
        }
    }
    @media screen and (max-width: 550px) {
        .ant-form-item-label [for='root_tellTheTeam'] {
            padding-bottom: 4rem !important;
        }
    }
`;

export default GlobalStyle;
