/* eslint no-nested-ternary: 0 */
import React, { FC } from 'react';
import styled from 'styled-components';
import { BgOptions } from '../../types/HeroBanner';
import {
  StyledTitle,
  StyledParagraph,
  StyledSubtitle,
} from '../../styles/typography';
import { customTheme } from '../../theme';
import StyledImage from '../Image/StyledImage';

const StyledBanner = styled.div<Pick<IHeroBannerProps, 'imagePosition' | 'bg'>>`
  display: flex;
  flex-direction: ${(props) =>
    props.imagePosition && props.imagePosition === 'right'
      ? 'row'
      : 'row-reverse'};
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme, imagePosition, bg }) => `
    color: ${
      bg === BgOptions.DARK
        ? `${theme.palette.white}`
        : `${theme.palette.black}`
    };

    @media (min-width: ${theme.breakpoints.extraWide}){
      width: 70%;
    }

    @media(max-width: ${theme.breakpoints.desktop}){
      align-items: flex-start;
    } 

    @media(max-width: ${theme.breakpoints.mobile}){
      flex-direction: ${
        imagePosition === 'right' ? 'column-reverse' : 'column'
      };
    } 
  `}
`;

const StyledBannerItem = styled.div<Pick<IHeroBannerProps, 'imagePosition'>>`
  width: 50%;
  ${({ theme, imagePosition }) => `
    margin-${imagePosition}: ${theme.spacing.veryLarge};

    @media (max-width: ${theme.breakpoints.tablet}){
      margin-${imagePosition}: ${theme.spacing.veryLarge};
    }

    @media (max-width: ${theme.breakpoints.mobile}){
      width: 100%;
    }
  `}
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  > a > button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: none;
  }
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.tablet}){
        display:flex;
        flex-direction: row;
      }
    @media (max-width: ${theme.breakpoints.extraWide}){
        margin-bottom: ${theme.spacing.superLarge};
      }
  `}
`;

export interface IHeroBannerProps {
  title?: string;
  subtitle?: string;
  content: string | string[];
  imagePath?: string;
  fallbackImage?: string;
  imagePosition?: string;
  bg?: BgOptions;
  children?: any;
}

const HeroBanner: FC<IHeroBannerProps> = ({
  title,
  subtitle,
  content,
  imagePath,
  fallbackImage = './../assets/images/fallback.jpg',
  imagePosition = 'right',
  bg = BgOptions.DARK,
  children,
}) => {
  return (
    <StyledBanner imagePosition={imagePosition} bg={bg}>
      <StyledBannerItem imagePosition={imagePosition}>
        {title && (
          <StyledTitle $textCapitalize level={1} bg={bg}>
            {title}
          </StyledTitle>
        )}
        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        {content ? (
          typeof content === 'string' ? (
            <StyledParagraph
              width={519}
              color={
                bg === BgOptions.DARK
                  ? customTheme.palette.white
                  : customTheme.palette.black
              }
              mb={customTheme.spacing.superLarge}>
              {content}
            </StyledParagraph>
          ) : (
            content.map((item) => (
              <StyledParagraph
                color={
                  bg === BgOptions.DARK
                    ? customTheme.palette.white
                    : customTheme.palette.black
                }
                key={item.length}>
                {item}
              </StyledParagraph>
            ))
          )
        ) : null}
        {children && <ActionsContainer>{children}</ActionsContainer>}
      </StyledBannerItem>
      {imagePath && (
        <StyledImage
          src={imagePath}
          fallback={fallbackImage}
          alt="hero image"
        />
      )}
    </StyledBanner>
  );
};

export default HeroBanner;
