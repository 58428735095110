import { TFunction } from 'i18next';
import determineEligibleAge from '../../utils/determineEligibleAge';
import { PrescreenQuestionsSchema } from '../../core';

export const mainSchema = (t: TFunction, country?: string) => {
  /*
   *  Get the current pathname, e.g. "/en-CA" or "/fr-CA"
   *  country returns 'undefined' in some cases as it is an optional parameter of this function
   *  so we can use the pathname to determine the locale
   */
  const { pathname } = new URL(window.location.href);

  const schema: PrescreenQuestionsSchema = {
    type: 'object',
    definitions: {
      yesNoUnknown: {
        type: 'string',
        enum: ['yes', 'no', 'unknown'],
        enumNames: [
          t('form.options.yes'),
          t('form.options.no'),
          t('form.options.unknown'),
        ],
      },
    },
    properties: {
      age: {
        uuid: '226b71d5-1286-45bc-b7df-7e356d22de53',
        title: t('form.preScreenQuestions.age'),
        type: 'integer',
        eligibilityLogic: {
          '>=': [{ var: '' }, determineEligibleAge(country)],
        },
        containsSensitiveInfo: true,
      },
      currentSmoker: {
        uuid: '22b0c1fe-6aa1-46c4-a369-1022f9f00e56',
        title: t('form.preScreenQuestions.currentSmoker'),
        $ref: '#/definitions/yesNoUnknown',
        eligibilityLogic: { '!==': [{ var: '' }, 'yes'] },
        containsSensitiveInfo: false,
      },
      beenOneYear: {
        uuid: '25d422cd-69eb-4e64-b3fa-892e85f295ec',
        title: t('form.preScreenQuestions.beenOneYear'),
        $ref: '#/definitions/yesNoUnknown',
        eligibilityLogic: { '!==': [{ var: '' }, 'no'] },
        containsSensitiveInfo: false,
      },
      takingMedications: {
        uuid: 'ddc13fdd-e7ac-467d-9d34-e4dba1ecdc66',
        title: t('form.preScreenQuestions.takingMedications'),
        $ref: '#/definitions/yesNoUnknown',
        eligibilityLogic: { '!==': [{ var: '' }, 'no'] },
        containsSensitiveInfo: false,
      },
      worseningHistory: {
        uuid: '7e359912-6de2-4c2d-9efe-9e3f761f21d2',
        title: t('form.preScreenQuestions.worseningHistory'),
        $ref: '#/definitions/yesNoUnknown',
        eligibilityLogic: { '!==': [{ var: '' }, 'no'] },
        containsSensitiveInfo: false,
      },
      haveCOPD: {
        uuid: '84d60ad0-d019-48be-9269-adae413afb7e',
        title: t('form.preScreenQuestions.haveCOPD'),
        $ref: '#/definitions/yesNoUnknown',
        eligibilityLogic: { '!==': [{ var: '' }, 'yes'] },
        containsSensitiveInfo: false,
      },
      tellTheTeam: {
        uuid: 'f9e6731e-e222-4414-a913-cc56219adcf0',
        title: t('form.preScreenQuestions.tellTheTeam'),
        type: 'string',
        containsSensitiveInfo: true,
      },
      consentCheckbox: {
        uuid: '3c8f535e-0fd6-4ab6-bda6-9e70553e585e',
        title: t('form.preScreenQuestions.consentCheckbox'),
        type: 'boolean',
        enum: [true],
        containsSensitiveInfo: false,
      },
    },
    required: [
      'age',
      'currentSmoker',
      'beenOneYear',
      'takingMedications',
      'worseningHistory',
      'haveCOPD',
      'consentCheckbox',
    ],
  };

  // Remove the "tellTheTeam" question if the user is in Canada
  if (pathname === '/en-CA') {
    delete schema.properties.tellTheTeam;
  } else if (pathname === '/fr-CA') {
    delete schema.properties.tellTheTeam;
  }

  return schema;
};

export const uiSchema = (t: TFunction, country?: string) => {
  const eligibleAge = determineEligibleAge(country);

  return {
    age: {
      'ui:autofocus': true,
      'ui:placeholder': t('form.placeholder.age', { age: eligibleAge }),
    },
    currentSmoker: {
      'ui:widget': 'radio',
    },
    beenOneYear: {
      'ui:widget': 'radio',
    },
    takingMedications: {
      'ui:widget': 'radio',
    },
    worseningHistory: {
      'ui:widget': 'radio',
    },
    haveCOPD: {
      'ui:widget': 'radio',
    },
    consentCheckbox: {
      'ui:widget': 'CustomCheckbox',
      'ui:options': {
        label: false,
      },
    },
  };
};

export const getPreScreenQuestionsFormSchema = (
  t: TFunction,
  country?: string,
) => {
  return { uiSchema: uiSchema(t, country), mainSchema: mainSchema(t, country) };
};

export default { uiSchema, mainSchema };
