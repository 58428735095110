import * as Bowser from 'bowser';

import i18n from './i18n';

export const getBrowserInfo = () => {
  const { userAgent } = window.navigator;
  const { browser, os, platform } = Bowser.parse(userAgent);

  const { host } = window.location;
  const currentUrl = window.location.href;

  const locale = i18n.language;
  const language = locale.split('-')[0];

  return {
    browser: browser.name,
    browserVersion: browser.version,
    device: platform.type,
    host,
    os: os.name,
    osVersion: os.version,
    url: currentUrl,
    locale,
    language,
  };
};
