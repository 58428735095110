import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NavLink } from '../Link/Link';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 4rem;
  ${({ theme }) => `
  background-color: ${theme.palette.sectionBackgroundSecondary};
  @media (max-width: ${theme.breakpoints.tablet}) {
    flex-direction: column;
    margin-left: 0;
  }
  @media (max-width: ${theme.breakpoints.tablet}) {
    padding: 2rem 1rem;
  }
`}
`;
const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
  gap: 1rem;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.tablet}) {
      flex-direction: column;
      width: 100%;
    }
  `}
`;

const Title = styled.a`
  font-weight: 700;
  font-size: 1.5rem;
  text-decoration: none;
  text-align: center;
  ${({ theme }) => `
    color: ${theme.palette.black};
    @media (max-width: ${theme.breakpoints.tablet}) {
      text-align: center;
      margin-bottom: 1rem;
    }
  `}
`;

const CopyrightText = styled.p`
  width: 100%;
  text-align: right;
  ${({ theme }) => `
  @media (max-width: ${theme.breakpoints.tablet}) {
    text-align: center;
  }
`}
`;
const FooterNavLink = styled(NavLink)`
  background-color: transparent;
`;
export interface FooterProps {
  title: string;
  copyrightText?: string;
  navItems: Array<{ id: number; text: string; href: string }>;
}

export const Footer: FunctionComponent<FooterProps> = ({
  title,
  copyrightText,
  navItems,
  children,
}) => {
  const currentPath = useLocation();

  return (
    <FooterWrapper>
      <Title
        data-mixpanelid="Home_Link_Bottom"
        href={`${currentPath.pathname}`}>
        {title}
      </Title>
      <Nav>
        {navItems.map((item) => (
          <FooterNavLink
            data-mixpanelid="Privacy_Policy_Footer_Link_Clicked"
            key={item.id}
            href={item.href}
            target="_blank">
            {item.text}
          </FooterNavLink>
        ))}
        {children}
      </Nav>
      {copyrightText && <CopyrightText>{copyrightText}</CopyrightText>}
    </FooterWrapper>
  );
};
