import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n, { sortedLangOptions } from '../../utils/i18n';
import { NavLink } from '../Link/Link';
import {
  LanguageSwitcher,
  LanguageSwitcherV2,
} from '../LanguageSwitcher/LanguageSwitcher';
import { useMediaQuery } from '../../core/hooks';
import { trackEvent } from '../../core/analytics';

export const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;

  ${({ theme }) => `
  background-color: ${theme.palette.white};
  @media (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 1rem 1rem;
  }
  `}
`;
const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => `
    gap: ${theme.spacing.veryLarge};

    @media (max-width: ${theme.breakpoints.tablet}) {
      width: 100%;
      gap: ${theme.spacing.small};
    }
    @media (max-width: ${theme.breakpoints.mobile}) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      gap: ${theme.spacing.small};

      a > button {
        display: none;
      }
    }
  `}
`;
const Title = styled.a`
  font-weight: 700;
  font-size: 1.5rem;
  text-decoration: none;

  ${({ theme }) => `
    color: ${theme.palette.black};
    @media (max-width: ${theme.breakpoints.tablet}) {
      margin-bottom: 1rem;
      text-align: center;
    }
  `}
`;

export interface HeaderProps {
  title: string;
  navItems?: Array<{ id: number; text: string; href: string }>;
  setTab: React.Dispatch<React.SetStateAction<string>>;
}

export const Header: FunctionComponent<HeaderProps> = ({
  title,
  navItems,
  children,
  setTab,
}) => {
  const currentPath = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(750);

  const languageChange = (value: string) => {
    i18n.changeLanguage(value).then(() => {
      navigate(`/${value}${currentPath.search}`, { replace: true });
      navigate(0);
      trackEvent('switched-locale', { locale: value });
    });
  };

  return (
    <HeaderWrapper>
      <Title data-mixpanelid="Nav_Link_Home" href={`${currentPath.pathname}`}>
        {title}
      </Title>
      <Nav>
        {navItems &&
          navItems.map((item) => (
            <NavLink
              data-mixpanelid={`Nav_Link_${item.href}`}
              key={item.id}
              href={item.href}
              onClick={
                item.href === '#locations-and-info'
                  ? () => setTab('locations')
                  : undefined
              }>
              {item.text}
            </NavLink>
          ))}
        {children}
        {isMobile ? (
          <LanguageSwitcher
            languageChange={languageChange}
            lngOptions={sortedLangOptions && sortedLangOptions}
          />
        ) : (
          <LanguageSwitcherV2
            languageChange={languageChange}
            lngOptions={sortedLangOptions && sortedLangOptions}
          />
        )}
      </Nav>
    </HeaderWrapper>
  );
};
