import styled from 'styled-components';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { TitleProps } from 'antd/lib/typography/Title';
import { BgOptions } from '../types/HeroBanner';

const { Title, Paragraph } = Typography;

export interface IStyledTitleProps extends TitleProps {
  bg?: BgOptions;
  left?: boolean;
  bold?: boolean;
  $textCapitalize?: boolean;
}

export const StyledTitle = styled(Title)<IStyledTitleProps>`
  ${({ theme, bg, left, bold, $textCapitalize }) => `
    ${left && `margin-right: ${theme.spacing.extraLarge}`};
    ${bold && `font-weight: bold`};
    &&& {
      margin-top: 0;
      ${$textCapitalize && `text-transform: capitalize;`}
      margin-bottom: ${theme.spacing.medium};
      line-height: 1.5;
      color: ${
        bg === BgOptions.DARK
          ? `${theme.palette.white}`
          : `${theme.palette.black}`
      };
      @media (max-width: ${theme.breakpoints.tablet}){
        font-size: ${theme.fontSizes.mediumLarge};
        font-weight: bold;
      }
      @media (max-width: ${theme.breakpoints.mobile}){
        width: ${theme.spacing.ultraLarge};
        margin-bottom: ${theme.spacing.medium};
        ${left && `margin-right: ${theme.spacing.large};`}
      }
    }
  `}
`;

export const StyledSubtitle = styled(Title)`
  ${({ theme }) => `
    &&& {
      margin-top: 0;
      margin-bottom: ${theme.spacing.medium};
    }
  `}
`;

interface IStyledParagraphProps extends ParagraphProps {
  width?: number;
  alignLeft?: boolean;
  mb?: string | 0;
  color?: string;
  size?: string;
  weight?: string;
}

export const StyledParagraph = styled(Paragraph)<IStyledParagraphProps>`
  ${({
    theme,
    color = theme.palette.default,
    size = theme.fontSizes.medium,
    weight = '400',
    alignLeft,
    width,
    mb = theme.spacing.medium,
  }) => `
    ${
      alignLeft
        ? `align-self: flex-start; 
      margin-right: ${theme.spacing.veryLarge}; `
        : ''
    }
    width: ${width ? `${width}px` : 'auto'};
    line-height: ${theme.fonts.lineHeightLarge};
    &&& {
      font-size: ${size};
      font-weight: ${weight} !important;
      color: ${color};
      margin-bottom: ${mb};
    }
    
    @media (max-width: ${theme.breakpoints.tablet}){
      width: ${width && '300px'};
      font-size: ${theme.fontSizes.medium};
    }
    @media (max-width: ${theme.breakpoints.mobile}){
      &&& { font-size: ${theme.fontSizes.mediumSmall};}
    }
  `}
`;
