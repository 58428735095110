import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Section, { StyledFlex } from '../components/Section';
import { customTheme } from '../theme';
import { BgOptions } from '../types/HeroBanner';
import HeroBanner from '../components/Hero/HeroBanner';
import BaseButton from '../components/buttons/BaseButton';
import {
  CardContainer,
  InfoCard,
  InfoCardContent,
} from '../components/Card/Card';
import {
  MapSection,
  MapSectionNavigation,
} from '../components/MapSection/MapSection';
import { TrialInfoAboutTrialSection } from '../components/TrialInfoAboutTrialSection/TrialInfoAboutTrialSection';
import EligibilitySection from '../components/EligibilitySection/EligibilitySection';
import Accordion from '../components/FAQ/Accordion';
import {
  People,
  TwoPeople,
  Plant,
  TwoPeople2,
  Clipboard,
} from '../components/icons/SVGs';
import StyledImage from '../components/Image/StyledImage';
import { StyledSubtitle } from '../styles/typography';
import { Container } from '../components/Container/Container';
import { useTab } from '../components/HeaderAndFooter/HeaderAndFooter';
import i18n from '../utils/i18n';
import { determineImages } from '../utils/determineImages';
import { trackAnonymousEvent } from '../core/analytics';

const Home = () => {
  const { t } = useTranslation();
  const { tab, setTab } = useTab();

  const { coverImg, eligibilityImg, eligibilityImg_fall, bg } = useMemo(
    () => determineImages(i18n.language),
    [i18n.language],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = Object.fromEntries(searchParams);
  useEffect(() => {
    if (!queryParams.hasInitialized) {
      trackAnonymousEvent('app-initialized', { locale: i18n.language });
      setSearchParams({ ...queryParams, hasInitialized: 'true' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <Section
        imgPath={coverImg}
        direction="row"
        justify="flex-start"
        align="center"
        bg={bg}
        position="relative">
        <Container>
          <HeroBanner
            title={t('heroImage.title')}
            content={t('heroImage.content')}
            bg={bg}>
            <a href="#locations-and-info" onClick={() => setTab('locations')}>
              <BaseButton data-mixpanelid="Find_a_Location_Anchor_Button">
                {t('common.findLocationNearText')}
              </BaseButton>
            </a>
          </HeroBanner>
        </Container>
        <CardContainer>
          <InfoCard
            Icon={Plant}
            direction="column"
            justify="flex-start"
            align="flex-start"
            pv={customTheme.spacing.large}
            ph={customTheme.spacing.veryLarge}>
            <InfoCardContent
              content={t('heroImage.card1')}
              direction="column"
              ph={0}
              pv={customTheme.spacing.small}
            />
          </InfoCard>
          <InfoCard
            Icon={TwoPeople}
            direction="column"
            justify="flex-start"
            align="flex-start"
            pv={customTheme.spacing.large}
            ph={customTheme.spacing.veryLarge}>
            <InfoCardContent
              content={t('heroImage.card2')}
              direction="column"
              ph={0}
              pv={customTheme.spacing.small}
            />
          </InfoCard>
        </CardContainer>
      </Section>

      <StyledFlex pv={customTheme.spacing.veryLarge} />
      <Container>
        <Section
          bg={BgOptions.WHITE}
          direction="row"
          justify="center"
          gap={customTheme.spacing.superLarge}
          align="center">
          <StyledImage
            src={eligibilityImg}
            fallback={eligibilityImg_fall}
            alt="woman breathing in"
          />
          <EligibilitySection text={t('eligibilitySection.subText')}>
            <StyledSubtitle level={3}>
              {t('eligibilitySection.title')}
            </StyledSubtitle>

            <InfoCard
              Icon={Clipboard}
              direction="row"
              align="center"
              height={15}
              pv={customTheme.spacing.mediumLarge}>
              <InfoCardContent
                title={t('eligibilitySection.card1.title')}
                content={t('eligibilitySection.card1.text')}
                direction="column"
                pl={customTheme.spacing.mediumLarge}
              />
            </InfoCard>
            <InfoCard
              Icon={People}
              direction="row"
              align="center"
              height={15}
              pv={customTheme.spacing.mediumLarge}>
              <InfoCardContent
                title={t('eligibilitySection.card2.title')}
                content={t('eligibilitySection.card2.text', {
                  age: i18n.language === 'ko-KR' ? 19 : 18,
                })}
                direction="column"
                pl={customTheme.spacing.mediumLarge}
              />
            </InfoCard>
            <InfoCard
              Icon={TwoPeople2}
              direction="row"
              align="center"
              height={15}
              pv={customTheme.spacing.mediumLarge}>
              <InfoCardContent
                title={t('eligibilitySection.card3.title')}
                content={t('eligibilitySection.card3.text')}
                direction="column"
                pl={customTheme.spacing.mediumLarge}
              />
            </InfoCard>
          </EligibilitySection>
        </Section>
      </Container>

      <Container>
        <MapSectionNavigation
          navItems={[
            {
              id: 1,
              text: t('stickyNav.findLocation'),
              href: '#locations-and-info',
              name: 'locations',
            },
            {
              id: 2,
              text: t('stickyNav.trialInfo'),
              href: '#locations-and-info',
              name: 'trial-info',
            },
          ]}
          activeLink={tab}
          setTab={setTab}
          sectionId="locations-and-info"
        />
      </Container>

      {tab === 'locations' ? (
        <Section
          bg={BgOptions.LIGHT}
          sectionId="locations"
          direction="column"
          align="flex-start">
          <Container>
            <MapSection />
          </Container>
        </Section>
      ) : (
        <Section
          bg={BgOptions.LIGHT}
          sectionId="trial-info"
          direction="column"
          align="flex-start">
          <Container>
            <TrialInfoAboutTrialSection />
          </Container>
        </Section>
      )}

      <Container>
        <Section
          direction="column"
          align="center"
          sectionId="faq"
          bg={BgOptions.WHITE}
          title={t('faq.title')}>
          <Accordion
            title={t('faq.questions.first')}
            mixpanelId="faq.questions.first">
            <p>{t('faq.answers.first')}</p>
          </Accordion>
          <Accordion
            title={t('faq.questions.second')}
            mixpanelId="faq.questions.second">
            <p>
              {t('faq.answers.second', {
                alreadyApprovedText: t('common.alreadyApprovedText'),
                studyMedicationName: t('common.studyMedicationName'),
              })}
            </p>
          </Accordion>
          <Accordion
            title={t('faq.questions.third')}
            mixpanelId="faq.questions.third">
            <p>{t('faq.answers.third.part1')}</p>
            <p>{t('faq.answers.third.part2')}</p>
          </Accordion>
        </Section>
      </Container>
    </>
  );
};

export default Home;
