import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Calendar, Cycle, Person } from '../icons/SVGs';
import circle from '../../assets/images/circle.png';
import { StyledParagraph } from '../../styles/typography';
import { customTheme } from '../../theme';
import { dateConvert } from '../../utils/dateConversion';

export const Description = styled.p`
  width: 50%;
  align-self: flex-start;
  line-height: 1.5;
  ${({ theme }) => `
    color: ${theme.palette.default};
    font-size: ${theme.fontSizes.medium};
    margin: ${theme.spacing.medium} 0 ${theme.spacing.large} 0;
    @media (max-width: ${theme.breakpoints.tablet}) {
        width: 100%;
      }
  `}
`;

const CircleRatio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${({ theme }) => `
    width: ${theme.spacing.extraLarge2};
    height: ${theme.spacing.extraLarge2};
    font-size: ${theme.fontSizes.mediumLarge};
    background: url(${circle});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: ${theme.palette.default};
    @media (max-width: ${theme.breakpoints.tablet}) {
        width: ${theme.spacing.extraLarge};
        height: ${theme.spacing.extraLarge};
        align-self: center;
      }
  `}
`;
const CardWrapper = styled.div`
  width: 100%;
  align-self: flex-start;
  display: flex;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.12);
  ${({ theme }) => `
    gap: ${theme.spacing.large};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.veryLarge};
    border-radius: ${theme.spacing.small};
    @media (max-width: ${theme.breakpoints.tablet}) {
        flex-direction: column;
      }
  `}
`;
const ContentWrapper = styled.div<{
  justify?: React.CSSProperties['justifyContent'];
}>`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  ${({ theme, justify }) => `
    color: ${theme.palette.default};
    font-size: ${theme.fontSizes.medium};
    gap: ${theme.spacing.mediumSmall};
    justify-content: ${justify}
  `}
`;
const ContentRow = styled.div`
  display: flex;
  align-items: center;
`;
interface CardProps {
  Icon: FunctionComponent<{ fill?: string }>;
  ratio?: string;
  cardTextFirst: React.ReactElement;
  cardTextSecond: React.ReactElement;
}
export const AboutTrialCard: FunctionComponent<CardProps> = ({
  Icon,
  ratio,
  cardTextFirst,
  cardTextSecond,
}) => {
  return (
    <CardWrapper>
      <CircleRatio>
        <Icon fill="#a5a9bc" />
        {ratio}
      </CircleRatio>
      <ContentWrapper justify="center">
        <ContentRow>
          <StyledParagraph
            size={customTheme.fontSizes.mediumLarge}
            color={customTheme.palette.black}
            weight={customTheme.fonts.fontWeight400}>
            {cardTextFirst}
          </StyledParagraph>
        </ContentRow>
        <ContentRow>
          <StyledParagraph
            size={customTheme.fontSizes.mediumLarge}
            color={customTheme.palette.black}
            weight={customTheme.fonts.fontWeight400}>
            {cardTextSecond}
          </StyledParagraph>
        </ContentRow>
      </ContentWrapper>
    </CardWrapper>
  );
};
const InfoItemWrapper = styled.div`
  display: flex;
  width: 50%;
  ${({ theme }) => `
    gap: ${theme.spacing.medium};
    margin-top: ${theme.spacing.large};
    @media (max-width: ${theme.breakpoints.tablet}) {
        width: 100%;
      }
  `}
`;
export const InfoWrapper = styled.div`
  display: flex;
  line-height: 1.5;
  ${({ theme }) => `
    gap: ${theme.spacing.superLarge};
    margin-top: ${theme.spacing.veryLarge};
    margin-bottom: ${theme.spacing.superLarge2};
    @media (max-width: ${theme.breakpoints.tablet}) {
        flex-direction: column;
        gap: ${theme.spacing.medium};
      }
  `}
`;
interface InfoProps {
  Icon?: FunctionComponent;
  title: string;
}
const InfoTitle = styled.p`
  ${({ theme }) => `
  font-size: ${theme.fontSizes.mediumLarge};
  font-weight: bold;
  color: ${theme.palette.primary};
`}
`;
export const AboutTrialInfo: FunctionComponent<InfoProps> = ({
  Icon,
  title,
  children,
}) => {
  return (
    <InfoItemWrapper>
      {Icon && (
        <div>
          <Icon />
        </div>
      )}
      <ContentWrapper justify="flex-start">
        <InfoTitle>{title}</InfoTitle>
        {children}
      </ContentWrapper>
    </InfoItemWrapper>
  );
};
export const DatesContent = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    gap: ${theme.spacing.medium};
  `}
`;
export const AboutTrialSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Description>{t('aboutTrial.description')}</Description>
      <AboutTrialCard
        Icon={Person}
        ratio="50:50"
        cardTextFirst={
          <Trans
            i18nKey="aboutTrial.cardText.first"
            values={{ studyMedicationName: t('common.studyMedicationName') }}
            components={{
              bold: <strong />,
            }}
          />
        }
        cardTextSecond={
          <Trans
            i18nKey="aboutTrial.cardText.second"
            components={{ bold: <strong /> }}
          />
        }
      />
      <InfoWrapper>
        <AboutTrialInfo
          Icon={Cycle}
          title={t('aboutTrial.phaseNumber', { phaseNumber: 3 })}>
          <p>{t('aboutTrial.info.phase3Description')}</p>
        </AboutTrialInfo>
        <AboutTrialInfo Icon={Calendar} title={t('aboutTrial.info.datesText')}>
          <DatesContent>
            <b>{t('aboutTrial.info.lastPatientFirstVisitDateLabel')}</b>
            <p>{capitalize(dateConvert(new Date(2024, 6, 1)))}</p>
            <b>{t('aboutTrial.info.studyCompletionDateLabel')}</b>
            <p>{capitalize(dateConvert(new Date(2027, 9, 1)))}</p>
          </DatesContent>
        </AboutTrialInfo>
      </InfoWrapper>
    </>
  );
};
