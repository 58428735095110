/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  V1Site,
  V1SiteWithDistance,
  INamedLocation,
} from '@reifyhealth/microsite-components';
import { useGeolocationCurrentPosition } from '../../core/hooks';
import { ReifyPlaceSearchInput } from './ReifyPlaceSearchInput';
import ReifyGoogleMaps from './ReifyGoogleMaps';
import { computeDistance, toLatLng } from '../../utils/MapConversions';
import { SiteList } from './SiteList';

export const defaultLocation = {
  latlng: toLatLng(42.3561158, -71.0603978),
  formattedAddress: '',
};

export interface ISiteLookupProps {
  /**
   * Microsite trial locations
   */
  sites?: V1Site[];
  /**
   * getSites query callback
   */
  getSitesCallback?: () => Promise<V1Site[]>;
  /**
   * getSites query options
   */
  getSitesCallbackOptions?: UseQueryOptions<V1Site[]>;
  /**
   * Callback function to execute on site list item button click
   */
  onSiteListItemClick: (site: V1Site) => void;
  /**
   * SiteList Button text to display
   */
  siteListButtonText?: string;
  /**
   * Distance label to show in site list (eg. miles, km)
   */
  distanceUnit?: 'miles' | 'kilometers';
  /**
   * Text to show as label for search
   */
  searchInputLabelText?: string;
  /**
   * Custom className(s) to attach
   */
  className?: string | string[];
}

export const SiteLookupContainer = styled(Row)`
  border: 1px solid ${({ theme }) => theme.siteLookupContainer.borderColor};
`;

export const SiteMapCol = styled(Col)`
  flex: 2;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const SiteListCol = styled(Col)`
  flex: 1;
  max-width: 33%;
  min-width: 33%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 100% !important;
    min-width: 100% !important;
  }
`;

const transformSiteInfo = (sites: V1Site[]): V1Site[] => {
  return sites.map(
    (site) =>
      ({
        latitude: site.latitude,
        longitude: site.longitude,
        siteId: site.siteId,
        sponsorSiteId: site.sponsorSiteId,
        city: site.city,
        state: site.state,
        street: site.street,
        postalCode: site.postalCode,
        siteName: site.siteName,
        phoneNumber: site.phoneNumber,
        country: site.country,
      } as V1Site),
  );
};

export const SiteLookup = ({
  sites,
  getSitesCallback,
  getSitesCallbackOptions = {},
  onSiteListItemClick,
  siteListButtonText = 'See if you Pre-Qualify',
  searchInputLabelText = 'Search for address',
  distanceUnit = 'miles',
  className,
}: ISiteLookupProps) => {
  // adapter for legacy usage of `sites` rather than `getSitesCallback`
  if (!getSitesCallback) {
    // eslint-disable-next-line no-param-reassign
    getSitesCallback = async (): Promise<V1Site[]> =>
      transformSiteInfo(sites || []);
  }

  const [siteData, setSiteData] = useState<V1SiteWithDistance[]>([]);
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>();
  // We need separate states so that the marker does not bounce when clicked
  const [clickedMarkerSiteId, setClickedMarkerSiteId] = useState<
    string | null
  >();
  const { location: userLocation } = useGeolocationCurrentPosition();
  const [location, setLocation] = useState<INamedLocation>(defaultLocation);
  const mapRef = useRef<google.maps.Map | null>();

  const { data: sitesQueryData } = useQuery<V1Site[]>(
    ['sites'],
    getSitesCallback,
    getSitesCallbackOptions,
  );

  const onMapLoad = useCallback(function onLoadMap(
    mapInstance: google.maps.Map | null,
  ) {
    mapRef.current = mapInstance;
    mapRef.current?.setZoom(5);
  },
  []);

  const onMapUnmount = useCallback(function onMapUnmount() {
    mapRef.current = null;
  }, []);

  useEffect(() => {
    if (userLocation && location === defaultLocation) {
      setLocation(userLocation);
    }
  }, [location, userLocation]);

  const findDistances = (
    sites: V1Site[],
    location: INamedLocation,
    distanceUnit: 'miles' | 'kilometers',
  ): V1SiteWithDistance[] => {
    const sitesWithDistance = sites.map((site) => ({
      ...site,
      distance: computeDistance(
        location.latlng.lat,
        location.latlng.lng,
        site.latitude,
        site.longitude,
        distanceUnit,
      ),
    }));

    return sortBy(sitesWithDistance, ['distance']);
  };

  useEffect(() => {
    setSiteData(findDistances(sitesQueryData || [], location, distanceUnit));
  }, [location, distanceUnit, sitesQueryData]);

  useEffect(() => {
    if (mapRef.current && siteData && siteData.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      mapRef.current.setCenter(location.latlng);
      bounds.extend(location.latlng);
      siteData.slice(0, 10).forEach((itm) => {
        bounds.extend({ lat: itm.latitude, lng: itm.longitude });
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [location.latlng, siteData]);

  return (
    <SiteLookupContainer
      className={`msc-site-lookup__container ${
        typeof className === 'string' ? className : className?.join(' ')
      }`}>
      <SiteListCol>
        <ReifyPlaceSearchInput
          currentLocation={location}
          onPlaceChanged={setLocation}
          labelText={searchInputLabelText}
        />
        <SiteList
          onItemClicked={(itm) => {
            setSelectedSiteId(itm.siteId);
          }}
          onItemButtonClicked={onSiteListItemClick}
          data={siteData}
          selectedItemId={selectedSiteId || clickedMarkerSiteId || ''}
          siteListButtonText={siteListButtonText}
          distanceUnit={distanceUnit}
        />
      </SiteListCol>
      <SiteMapCol className="msc-site-lookup__map">
        <ReifyGoogleMaps
          onMapLoaded={onMapLoad}
          onMapUnmount={onMapUnmount}
          mapData={siteData}
          currentLocation={location.latlng}
          selectedItemId={selectedSiteId || ''}
          onMarkerClicked={(_evt, itm) => {
            document
              .getElementById(`site-list-item-${itm.siteId}`)
              ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            setClickedMarkerSiteId(itm.siteId);
            setSelectedSiteId(null);
          }}
        />
      </SiteMapCol>
    </SiteLookupContainer>
  );
};

export default SiteLookup;
