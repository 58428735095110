const polyfillData: any = {};
const polyfillSessionStorage = {
  setItem(key: string, value: any) {
    polyfillData[key] = value;
  },
  getItem(key: string) {
    return polyfillData[key];
  },
};

// eslint-disable-next-line import/no-mutable-exports
let mySessionStorage: any;
if (typeof window !== 'undefined') {
  try {
    mySessionStorage = window.sessionStorage;
  } catch {
    // eslint-disable-next-line no-console
    console.log('session storage error, polyfilling');
    mySessionStorage = polyfillSessionStorage;
  }
}

export default mySessionStorage;
