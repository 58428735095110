import { Locale, PrescreenData, PrescreenQuestion } from '../core/types';

export const constructPrescreenDataPayload = (
  micrositeId: string,
  trialId: string,
  isEligible: boolean,
  siteId: string,
  sponsorSiteId: string,
  locale: Locale,
  prescreenEligibilityLogic: any,
  questions: any[],
  subjectId: string,
  utmParams: any,
): PrescreenData => {
  const transformedQuestions: PrescreenQuestion[] = questions.map(
    (question: any) => {
      return {
        linkId: question.linkId,
        text: question.question,
        type: question.type,
        answers: question.sensitive
          ? undefined
          : [
              {
                value: question.answer,
              },
            ],
        passing: question.passing,
      };
    },
  );

  const payload: PrescreenData = {
    siteId,
    micrositeId,
    body: {
      response: {
        trialId,
        sponsorSiteId,
        micrositeId,
        locale,
        prescreenEligibilityLogic,
        questions: transformedQuestions,
        // if the subject is eligible, a subjectID must be provided
        isEligible,
        subjectId: isEligible ? subjectId : undefined,
      },
    },
  };

  if (utmParams && Object.keys(utmParams).length > 0) {
    if (utmParams.utm_source) {
      payload.body.response.utmSource = utmParams.utm_source;
    }

    if (utmParams.utm_medium) {
      payload.body.response.utmMedium = utmParams.utm_medium;
    }

    if (utmParams.utm_campaign) {
      payload.body.response.utmCampaign = utmParams.utm_campaign;
    }

    if (utmParams.utm_term) {
      payload.body.response.utmTerm = utmParams.utm_term;
    }

    if (utmParams.utm_content) {
      payload.body.response.utmContent = utmParams.utm_content;
    }
  }

  return payload;
};
