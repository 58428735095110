import styled from 'styled-components';

export const NavLink = styled.a`
  border-radius: 0.3rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  ${({ theme }) => `
    color: ${theme.palette.primary};
    font-size: ${theme.fontSizes.mediumSmall};
    margin: 0;
    @media (max-width: ${theme.breakpoints.tablet}) {
      text-align: center;
      overflow: hidden;
      padding: ${theme.spacing.extraSmall} ${theme.spacing.medium};
    }

    @media (max-width: ${theme.breakpoints.mobile}) {
      background-color: ${theme.palette.navLinkMobile};
      width: 100%;
      height: auto;
      text-align: center;
      overflow: hidden;
      padding: ${theme.spacing.extraSmall} ${theme.spacing.medium};
    }
  `}
`;
