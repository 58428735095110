import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Pseudo from 'i18next-pseudo';
import config from '../config';

export const { supportedLanguages } = config;

export const groupedLanguageOptions: {
  [key: string]: Array<string>;
} = {};

supportedLanguages.forEach((locale) => {
  const country = new Intl.DisplayNames(['en'], { type: 'region' }).of(
    locale.slice(3, 5),
  ) as string;
  locale.slice(3, 5);
  if (!Object.keys(groupedLanguageOptions).includes(country)) {
    groupedLanguageOptions[country] = [locale];
  } else {
    groupedLanguageOptions[country].push(locale);
  }
});

export const sortedLangOptions: {
  [key: string]: Array<string>;
} = Object.keys(groupedLanguageOptions)
  .sort()
  .reduce((accumulator, key) => {
    accumulator[key] = groupedLanguageOptions[key];
    return accumulator;
  }, {} as Record<string, Array<string>>);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n
  .use(
    new Pseudo({
      enabled: config.isPseudoEnabled, // for visual testing ONLY
      languageToPseudo: 'en-US',
    }),
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(backend)
  .init({
    postProcess: ['pseudo'],
    debug: config.env === 'development',
    returnObjects: true,
    fallbackLng: 'en-US',
    load: 'currentOnly', // e.g en-US, es-US etc
    interpolation: {
      escapeValue: false,
    },
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'path', 'navigator', 'htmlTag'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: false,
      crossDomain: false,
      withCredentials: false,
      overrideMimeType: false,

      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      },
    },
  });

export const translate: TFunction = i18n.t.bind(i18n);

export default i18n;
