import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, Button, Alert } from 'antd';
import { V1Site, V1SiteWithDistance } from '@reifyhealth/microsite-components';

import { useTranslation } from 'react-i18next';
import { formatAddress } from '../../utils/MapConversions';

export interface ISiteListProps {
  /**
   * Site details
   */
  data: V1SiteWithDistance[];
  /**
   * Callback function to execute on site item click
   */
  onItemClicked: (site: V1Site) => void;
  /**
   * Callback function to execute on site item button click
   */
  onItemButtonClicked: (site: V1Site) => void;
  /**
   * Selected item id if item is selected
   */
  selectedItemId?: string;
  /**
   * Site list item button label
   */
  siteListButtonText: string;
  /**
   * Distance text to show
   */
  distanceUnit: string;
  /**
   * Custom className(s) to attach
   */
  className?: string | string[];
}

export interface ISiteListItemProps {
  siteInfo: any;
  onItemButtonClicked: () => void;
  selected: boolean;
  onItemClicked: (item: V1Site) => void;
  siteListButtonText: string;
  distanceUnit: string;
  className?: string | string[];
}

export type AddressDisplayProps = Partial<
  Pick<
    V1Site,
    'city' | 'state' | 'postalCode' | 'country' | 'phoneNumber' | 'street'
  >
>;

export const SiteAddressWrapper = styled.p`
  font-size: 1.25rem;
`;

export const DistanceDisplay = styled.div`
  text-align: right !important;
  font-weight: 700 !important;
  color: ${({ theme }) => theme.palette.primaryColor} !important;
  font-size: 1rem;
`;

const selectedListItemWrapperBorder = css`
  border-left: ${({ theme }) =>
    `${theme.siteList.itemWrapper.selectedBorder} ${theme.palette.primaryColor}`};
`;

export const SiteListItemWrapper = styled.li<{ selected: boolean }>`
  border: ${({ theme }) => theme.siteList.itemWrapper.border};
  border-bottom: none;
  border-left: none;
  border-color: ${({ theme }) => theme.siteList.itemWrapper.borderColor};
  padding: 1rem !important;
  ${({ selected }) => (selected ? selectedListItemWrapperBorder : '')}

  & address {
    font-size: 0.9rem;
    padding: 0;
    font-style: normal;
    color: ${({ theme }) => theme.palette.black};
  }

  & address a {
    color: ${({ theme }) => theme.palette.black};
  }
  & .ant-row {
    flex-flow: unset;
  }
`;

export const SiteListAddressCol = styled(Col)`
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 0.75rem;
`;

export const SiteLink = styled.a`
  font-weight: ${({ theme }) => theme.siteList.siteLink.fontWeight};
  font-size: ${({ theme }) => theme.siteList.siteLink.fontSize};
  text-align: ${({ theme }) => theme.siteList.siteLink.textAlign};
  color: ${({ theme }) => theme.palette.primaryColor};
  line-height: ${({ theme }) => theme.siteList.siteLink.lineHeight};
  padding-bottom: 0.75rem !important;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: ${({ theme }) => theme.palette.primaryColor};
  }
`;

export const SiteOrderedList = styled.ol`
  padding: 0;
  list-style-type: none;
`;

export const SiteListWrapper = styled.div`
  & li:first-child {
    border-top: none;
  }
`;

export const AddressDisplay = ({
  city,
  state,
  postalCode,
  street,
  country,
  phoneNumber,
}: AddressDisplayProps) => {
  const phone = phoneNumber && phoneNumber !== '' ? phoneNumber : undefined;

  const showAddress = [
    city,
    state,
    postalCode,
    street,
    country,
    phoneNumber,
  ].some((value) => !!value);
  const location = formatAddress(city, state, postalCode);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showAddress && (
        <address>
          <SiteAddressWrapper className="msc-site-list-item__address">
            {location}
            {location && <br />}
            {country}
            {country && <br />}
            {phone && <a href={`tel:${phone}`}>{phone}</a>}
          </SiteAddressWrapper>
        </address>
      )}
    </>
  );
};

export const SiteListItem = ({
  siteInfo,
  selected,
  onItemButtonClicked,
  onItemClicked,
  siteListButtonText,
  distanceUnit,
  className,
}: ISiteListItemProps) => {
  const {
    distance,
    siteName,
    displayName,
    street,
    city,
    state,
    postalCode,
    country,
    phoneNumber,
    siteId,
  } = siteInfo;

  const { t, i18n } = useTranslation();
  return (
    <SiteListItemWrapper
      id={`site-list-item-${siteId}`}
      selected={selected}
      className={`msc-site-list-item__wrapper ${
        typeof className === 'string' ? className : className?.join(' ')
      }`}>
      <Row>
        <SiteListAddressCol>
          <SiteLink
            tabIndex={0}
            className="msc-site-list-item__link"
            onClick={(e) => {
              e.preventDefault();
              onItemClicked(siteInfo);
            }}>
            {displayName && displayName.length ? displayName : siteName}
          </SiteLink>
          <AddressDisplay
            city={city}
            phoneNumber={phoneNumber}
            state={state}
            country={country}
            street={street}
            postalCode={postalCode}
          />
        </SiteListAddressCol>
        <Col>
          <DistanceDisplay className="msc-site-list-item__distance">
            <div>{Math.floor(distance || 0)}</div>
            <span>
              {i18n.exists('common.distanceUnit')
                ? t('common.distanceUnit')
                : distanceUnit}
            </span>
          </DistanceDisplay>
        </Col>
      </Row>

      <Button
        data-mixpanelid="Map_Site_Form_Init"
        onClick={onItemButtonClicked}
        className="msc-site-list-item__button"
        type="primary">
        {siteListButtonText}
      </Button>
    </SiteListItemWrapper>
  );
};

export function SiteList({
  data,
  selectedItemId,
  onItemClicked,
  onItemButtonClicked,
  siteListButtonText,
  distanceUnit,
  className,
}: ISiteListProps) {
  return (
    <SiteOrderedList
      style={{ overflowY: 'scroll', height: 700 }}
      className={`msc-site-list__list ${
        typeof className === 'string' ? className : className?.join(' ')
      }`}>
      <SiteListWrapper
        id="site-list-container"
        className="msc-site-list__wrapper">
        {data &&
          data.map((site) => (
            <SiteListItem
              key={site.siteId}
              siteInfo={site}
              selected={selectedItemId === site.siteId}
              onItemClicked={onItemClicked}
              onItemButtonClicked={() => onItemButtonClicked(site)}
              siteListButtonText={siteListButtonText}
              distanceUnit={distanceUnit}
            />
          ))}
        {(!data || data.length === 0) && (
          <Alert
            closable
            message="No results found"
            className="msc-site-list__alert"
            type="warning"
            style={{ margin: '0.5rem' }}
          />
        )}
      </SiteListWrapper>
    </SiteOrderedList>
  );
}
