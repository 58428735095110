import { FunctionComponent, ReactElement, useState } from 'react';
import { Select, SelectProps } from 'antd';
import styled from 'styled-components';
import { capitalize, startCase } from 'lodash';
import i18n from '../../utils/i18n';
import { Caret } from '../icons/SVGs';
import { StyledCaret } from '../FAQ/Accordion';

const { Option, OptGroup } = Select;

const StyledDropdownButton = styled.div<{ isOpen: boolean }>`
  min-width: 225px;
  max-width: 280px;
  width: fit-content;
  z-index: 999;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 auto;
  position: relative;

  ${({ theme, isOpen }) => `
    ${
      isOpen
        ? ` 
        border-bottom: 0;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;`
        : ''
    }
    padding: ${theme.spacing.medium};
    background-color: ${theme.palette.white};
    border-radius: ${theme.spacing.medium};
    border: 1px solid gray;
  `}
`;

const StyledDropdownMenu = styled.div<{ rowsAndColumns: number }>`
  position: absolute;
  z-index: 999;
  right: 0;
  top: 100%;
  ${({ theme, rowsAndColumns }) => `
  display: grid;
  grid-template-columns: repeat(${rowsAndColumns}, 1fr);
  grid-gap: ${theme.spacing.mediumLarge} ${theme.spacing.medium};
  
  width: 650px;
  background-color: ${theme.palette.white};
  box-shadow: 0px 0px 2px gray;

  border-top-right-radius: 0;
  border-top-left-radius: ${theme.spacing.medium};
  border-bottom-right-radius: ${theme.spacing.medium};
  border-bottom-left-radius: ${theme.spacing.medium};
  
  overflow: hidden;
  padding: ${theme.spacing.large} ${theme.spacing.veryLarge};
  `}
`;

const LangContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LanguageGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  padding-left: 10px;
`;

const LanguageGroupTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const LanguageGroupLanguage = styled.span`
  ${({ theme }) => `
    font-size: 14px;
    color: ${theme.palette.primary};
    cursor: pointer;
    padding-bottom: 4px;
  `}
`;

const Overlay = styled.div`
  height: 100%;
  background-color: rgba(4, 50, 53, 0.75);
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const DropDownWrapper = styled.div`
  &&& .dropdown-item {
    text-align: center;
    ${({ theme }) => `
      width: 100% !important;
      padding: ${theme.spacing.medium};
    `};
  }
`;

const StyledSelect = styled(Select)`
  ${({ theme }) => `

      width: 225px;

    @media (max-width: ${theme.breakpoints.mobile}){
      width: 250px;
    }
  `};
`;

export interface LanguageSwitcherProps extends SelectProps {
  languageChange: (value: string) => void;
  lngOptions?: { [key: string]: Array<string> };
}

const dropdownRender = (dropdown: ReactElement) => (
  <DropDownWrapper>{dropdown}</DropDownWrapper>
);

export const LanguageSwitcherV2: FunctionComponent<LanguageSwitcherProps> = ({
  languageChange,
  lngOptions,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language || 'en-US',
  );
  const [isOpen, setIsOpen] = useState(false);

  const numOfGroups = lngOptions && Object.keys(lngOptions).length;
  let rowsAndColumns;
  if (numOfGroups) {
    if (numOfGroups < 5) rowsAndColumns = 2;
    else if (numOfGroups < 10) rowsAndColumns = 3;
    else if (numOfGroups < 17) rowsAndColumns = 4;
    else rowsAndColumns = 6;
  }

  return (
    <div style={{ position: 'relative' }}>
      <StyledDropdownButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        {`${
          capitalize(
            new Intl.DisplayNames([selectedLanguage], { type: 'language' }).of(
              selectedLanguage.slice(0, 2),
            ),
          ) as string
        } (${
          startCase(
            new Intl.DisplayNames(['en-US'], { type: 'region' }).of(
              selectedLanguage.slice(3, 5),
            ),
          ) as string
        })`}
        <StyledCaret open={isOpen}>
          <Caret h={15} w={10} />
        </StyledCaret>
        {isOpen && (
          <StyledDropdownMenu rowsAndColumns={rowsAndColumns ?? 2}>
            {lngOptions &&
              Object.keys(lngOptions).map((langGroup) => (
                <LangContainer key={langGroup}>
                  <LanguageGroupTitle>{langGroup}</LanguageGroupTitle>
                  <LanguageGroup>
                    {lngOptions[langGroup].map((lng) => (
                      <LanguageGroupLanguage
                        key={lng}
                        onClick={() => {
                          setSelectedLanguage(lng);
                          languageChange(lng);
                        }}>
                        {
                          capitalize(
                            new Intl.DisplayNames([lng], {
                              type: 'language',
                            }).of(lng.slice(0, 2)),
                          ) as string
                        }
                      </LanguageGroupLanguage>
                    ))}
                  </LanguageGroup>
                </LangContainer>
              ))}
          </StyledDropdownMenu>
        )}
      </StyledDropdownButton>
      {isOpen && <Overlay id="overlay" onClick={() => setIsOpen(!isOpen)} />}
    </div>
  );
};

export const LanguageSwitcher: FunctionComponent<LanguageSwitcherProps> = ({
  languageChange,
  lngOptions,
}) => (
  <StyledSelect
    dropdownRender={dropdownRender}
    value={i18n.language}
    onChange={(v) => languageChange(v as string)}>
    {lngOptions &&
      Object.keys(lngOptions).map((option) => (
        <OptGroup key={option} label={option}>
          {lngOptions[option].map((lang) => {
            return (
              <Option
                className="dropdown-item"
                key={lngOptions[option] + lang}
                value={lang}>
                {capitalize(
                  new Intl.DisplayNames([lang], { type: 'language' }).of(
                    lang.slice(0, 2),
                  ) as string,
                )}
              </Option>
            );
          })}
        </OptGroup>
      ))}
  </StyledSelect>
);
