export enum BannerOptions {
  'SIMPLE' = 'simple',
  'EXPANDED' = 'expanded',
  'FORM' = 'form',
}

export enum BgOptions {
  'DARK' = 'dark',
  'LIGHT' = 'light',
  'WHITE' = 'white',
}

export interface IHeroBannerOptions {
  type: BannerOptions;
}
