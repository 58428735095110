const determineEligibleAge = (country?: string): number => {
  switch (country) {
    case 'KR':
      return 19;
    default:
      return 18;
  }
};

export default determineEligibleAge;
