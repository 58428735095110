import React, { FC, ImgHTMLAttributes, useState } from 'react';
import { ImageProps } from 'antd';
import styled from 'styled-components';

export const StyledHeroImage = styled.img<ImageProps>`
  ${({ theme, width }) => `
  width: ${width}px;
  height: auto;
  @media (max-width: ${theme.breakpoints.desktop}){
      width: 400px;
      margin-bottom: ${theme.spacing.large};
    }
    @media (max-width: ${theme.breakpoints.tablet}){
      display: none;
    }
  `}
`;

export type IStyledImage = {
  src: string;
  fallback: string;
  alt?: string;
} & ImgHTMLAttributes<HTMLImageElement> &
  ImageProps;

const StyledImage: FC<IStyledImage> = ({
  src,
  fallback,
  alt = '',
  width = 500,
  ...rest
}) => {
  const [imageError, setImageError] = useState(false);

  return (
    <StyledHeroImage
      src={imageError ? fallback : src}
      alt={alt}
      width={width}
      decoding="async"
      loading="lazy"
      onError={() => setImageError(true)}
      {...rest}
    />
  );
};

export default StyledImage;
