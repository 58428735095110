import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  OrchestratorProvider,
  useGetSites,
  V1Site,
} from '@reifyhealth/microsite-components';
import { LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/src/utils/make-load-script-url';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Spin } from 'antd';
import { Modal } from '../Modal/Modal';
import { AtlasForms } from '../Form/MicrositeForm';
import config from '../../config';
import { StyledTitle } from '../../styles/typography';
import { trackEvent } from '../../core/analytics';
import { SiteLookup } from '../SiteLookup';
import mySessionStorage from '../../utils/sessionStoragePolyfill';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;
const libraries: Libraries = ['places'];

const Steps = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.default};
    font-size: ${theme.fontSizes.medium};
    margin-bottom: ${theme.fontSizes.mediumLarge};
  `}
`;

const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  ${({ theme }) => `
    background-color: ${theme.palette.white};
    gap: ${theme.spacing.veryLarge};
    padding: ${theme.spacing.large} 0 0 ${theme.spacing.veryLarge};
    height:  ${theme.spacing.superLarge};
    @media (max-width: ${theme.breakpoints.tablet}) {
        padding: ${theme.spacing.mediumLarge} 0 0 ${theme.spacing.large};
    }
    @media (min-width: ${theme.breakpoints.extraWide}) {
      padding: ${theme.spacing.large} 0 0 0;
  }
  `}
`;
const NavLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 5px solid transparent;

  ${({ theme }) => `
    color: ${theme.palette.black};
    font-size: ${theme.fontSizes.mediumLarge};
    &.active{
      border-color: ${theme.palette.primary};
    }

    @media (max-width: ${theme.breakpoints.mobile}) {
      font-size: ${theme.fontSizes.medium};
      font-weight: bold;
    }
  `}
`;

const MapSectionWrapper = styled.div`
  width: 100%;
  ${({ theme }) => `
    margin-top: -${theme.spacing.superLarge};
    @media (max-width: ${theme.breakpoints.tablet}) {
      margin-top: 0;
    }
  }
  `}
`;
const Spinner = styled(Spin)`
  margin-top: 2rem;
`;

export interface NavProps {
  navItems: Array<{
    id: number;
    text: string;
    href: string;
    name: string;
  }>;
  activeLink: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  sectionId: string;
}
export const MapSectionNavigation: FunctionComponent<NavProps> = ({
  navItems,
  activeLink,
  setTab,
  sectionId,
}) => {
  return (
    <NavWrapper id={sectionId}>
      {navItems &&
        navItems.map((item) => (
          <NavLink
            data-mixpanelid={`Tab_Nav_Link_${item.name}`}
            key={item.id}
            className={activeLink === item.name ? 'active' : ''}
            href={item.href}
            onClick={() => setTab(item.name)}>
            {item.text}
          </NavLink>
        ))}
    </NavWrapper>
  );
};

export const MapSection: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [modalData, setModalData] = useState<V1Site>();
  const [showModal, setShowModal] = useState(false);
  let distanceUnit: 'miles' | 'kilometers' | undefined;
  const country = i18n.language.slice(3, 5);

  const { data: sites = [], isLoading = true } = useGetSites(
    config.micrositeId,
    config.trialId,
  );

  switch (country) {
    case 'US':
    case 'GB':
      distanceUnit = 'miles';
      break;
    default:
      distanceUnit = 'kilometers';
      break;
  }
  const doShowModal = useCallback((show: boolean, payload?: any) => {
    // subject ID used by the prescreen report API and the submit referral API
    // should be set exactly once and be the same in both API calls
    mySessionStorage.setItem('subjectId', uuidv4());
    setModalData(payload || {});
    setShowModal(show);
  }, []);

  return (
    <MapSectionWrapper>
      <StyledTitle>{t('locations.title')}</StyledTitle>
      <Steps>
        <p>{t('locations.steps.first')}</p>
        <p>{t('locations.steps.second')}</p>
      </Steps>
      {isLoading ? (
        <Spinner />
      ) : (
        <LoadScript
          googleMapsApiKey={GOOGLE_API_KEY}
          libraries={libraries}
          region={i18n.language.length > 2 ? i18n.language.substring(3) : 'US'}
          language={
            i18n.language.length > 2 ? i18n.language.substring(0, 2) : 'en'
          }
          loadingElement={<div>{t('locations.loadingMap')}</div>}>
          <SiteLookup
            getSitesCallback={async () => sites}
            siteListButtonText={t('locations.siteButton')}
            searchInputLabelText={t('locations.searchPlaceholder')}
            distanceUnit={distanceUnit}
            onSiteListItemClick={(site) => {
              doShowModal(true, site);
              trackEvent('form-flow-launched');
            }}
          />
        </LoadScript>
      )}

      {showModal && (
        <Modal isVisible={showModal}>
          <OrchestratorProvider>
            <AtlasForms
              onFinish={() => {
                doShowModal(false, {});
                trackEvent('form-flow-closed');
              }}
              siteInfo={modalData || ({} as V1Site)}
            />
          </OrchestratorProvider>
        </Modal>
      )}
    </MapSectionWrapper>
  );
};
