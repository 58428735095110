import React from 'react';
import styled from 'styled-components';

import config from './config';

const StyledErrorScreen = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & h1 {
    text-align: center;
  }
`;

class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; error: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // TODO: maybe add some log service to handle error logs?
    if (config.env !== 'production') {
      // eslint-disable-next-line no-console
      console.log('ERROR', error);
      // eslint-disable-next-line no-console
      console.log('ERROR INFO', errorInfo);
    }
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError || error) {
      // You can render any custom fallback UI
      return (
        <StyledErrorScreen>
          <h1>Something went wrong.</h1>
        </StyledErrorScreen>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
