import React, { useState } from 'react';
import styled from 'styled-components';
import { CloseButton } from '../buttons/CloseButton';
import { WithChildren } from '../../core/types';

const NotificationWrapper = styled.div<{ bgColor?: string }>`
  ${({ theme, bgColor }) => `
    font-weight: ${theme.fonts.fontWeight};
    padding: ${theme.spacing.medium};
    background-color: ${bgColor || '#f5f5f5'};
    border-radius: ${theme.spacing.extraSmall};
    position: relative;
    margin-bottom:${theme.spacing.medium};
  `}
`;
export interface INotificationProps {
  message?: string;
  closable: boolean;
  className?: string;
  bgColor?: string;
  afterClose?: () => void;
}

export const Notification = ({
  message,
  closable,
  bgColor,
  children,
  afterClose,
}: WithChildren<INotificationProps>) => {
  const [showing, setShowing] = useState(true);

  return (
    <div>
      {showing && (
        <NotificationWrapper bgColor={bgColor}>
          {closable && (
            <CloseButton
              onClick={() => {
                if (afterClose) {
                  afterClose();
                }
                setShowing(false);
              }}
            />
          )}
          {message || children}
        </NotificationWrapper>
      )}
    </div>
  );
};

export default Notification;
