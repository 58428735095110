import { Breadcrumb, Typography, Tag } from 'antd';
import BreadcrumbSeparator from 'antd/lib/breadcrumb/BreadcrumbSeparator';
import React, { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ETrialStatus, trialStatusMap } from '../../types/trials';
import { StyledTitle } from '../../styles/typography';

const { Text, Paragraph } = Typography;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.medium};
    margin-bottom: ${theme.spacing.large};

    @media (max-width: ${theme.breakpoints.mobile}){
      margin-bottom: ${theme.spacing.medium};
    }
  `}
`;

const StyledBreadcrumbItem = styled(Breadcrumb.Item)<{ active?: boolean }>`
  ${({ theme, active }) => `
        & a {
            ${
              active
                ? `
                    color: ${theme.palette.primary} !important;
                  `
                : `
                    color: ${theme.palette.primaryLight} !important;
                    font-weight: bold;
                  `
            }
            @media (max-width: ${theme.breakpoints.mobile}){
              font-size: ${theme.fontSizes.mediumSmall};
            }
        }
    `}
`;

export const StyledTag = styled(Tag)`
  ${({ theme }) => `
    color: ${theme.palette.white};
    border-radius: ${theme.spacing.extraSmall};
    margin-left: ${theme.spacing.medium};
    padding: ${theme.spacing.extraSmall} ${theme.spacing.small};

    @media (max-width: ${theme.breakpoints.mobile}){
      margin-left: 0;
      padding: ${theme.spacing.extraSmall} ${theme.spacing.small};
    }
  `}
`;

export const StyledHeaderTrialName = styled(Paragraph)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.large};

    @media (max-width: ${theme.breakpoints.mobile}){
      margin-bottom: ${theme.spacing.medium};
    }
  `}
`;

export interface ITrialHeaderSectionProps {
  trialName: string;
  trialStatus: ETrialStatus;
  subtitle: string;
}

const TrialHeaderSection: FC<ITrialHeaderSectionProps> = ({
  trialName,
  trialStatus,
  subtitle,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathSnippets = location.pathname.split('/');

  const breadcrumbItems = [
    <StyledBreadcrumbItem key="home">
      <Link to="/">{t('trialHeader.home')}</Link>
    </StyledBreadcrumbItem>,
    <BreadcrumbSeparator key="separator">{' > '}</BreadcrumbSeparator>,
    <StyledBreadcrumbItem active key="trial">
      <Link to={location.pathname}>
        {pathSnippets[pathSnippets.length - 1].toUpperCase()}:{' '}
        {t('common.pageTitle')}
      </Link>
    </StyledBreadcrumbItem>,
  ];

  return (
    <HeaderSection>
      <StyledBreadcrumb separator=""> {breadcrumbItems} </StyledBreadcrumb>
      <StyledHeaderTrialName>
        <Text>{trialName}</Text>{' '}
        <StyledTag color={trialStatusMap.get(trialStatus)}>
          {t(`trialHeader.trialStatus.${trialStatus}`).toUpperCase()}
        </StyledTag>
      </StyledHeaderTrialName>
      <StyledTitle bold left>
        {subtitle}
      </StyledTitle>
    </HeaderSection>
  );
};

export default TrialHeaderSection;
