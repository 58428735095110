import { TFunction } from 'i18next';

export const mainSchema = (t: TFunction) => ({
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      title: t('form.basicInfoQuestions.firstName'),
    },
    lastName: {
      type: 'string',
      title: t('form.basicInfoQuestions.lastName'),
    },
    phone: {
      type: 'string',
      title: t('form.basicInfoQuestions.phone'),
      format: 'phone',
    },
    email: {
      type: 'string',
      title: t('form.basicInfoQuestions.email'),
      format: 'email',
    },
  },
  if: {
    properties: {
      email: { maxLength: 0, type: 'string' },
      phone: { maxLength: 0, type: 'string' },
    },
  },
  then: { required: ['firstName', 'lastName', 'email', 'phone'] },
  else: {
    if: {
      properties: {
        email: { maxLength: 0, type: 'string' },
      },
    },
    then: { required: ['firstName', 'lastName', 'phone'] },
    else: { required: ['firstName', 'lastName', 'email'] },
  },
});

export const uiSchema = (t: TFunction) => ({
  firstName: {
    'ui:placeholder': t('form.basicInfoQuestions.firstName'),
  },
  lastName: {
    'ui:placeholder': t('form.basicInfoQuestions.lastName'),
  },
  phone: {
    'ui:widget': 'PhoneNumberWidget',
  },
});

export const getBasicInfoFormSchema = (t: TFunction) => {
  return { uiSchema: uiSchema(t), mainSchema: mainSchema(t) };
};

export default { uiSchema, mainSchema };
