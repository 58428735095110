import { Typography } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import { StyledParagraph } from '../../styles/typography';

export const InfoCardWrapper = styled.div<
  Pick<
    CardProps,
    'direction' | 'justify' | 'align' | 'width' | 'height' | 'ph' | 'pv'
  >
>`
  display: flex;
  flex: 1;
  max-width: 450px;

  ${({
    theme,
    direction,
    justify,
    align,
    width,
    height,
    ph = theme.spacing.veryLarge,
    pv = theme.spacing.mediumLarge,
  }) => `
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align}; 

    width: ${width ? `${width}%` : '500px'};
    height: ${height ? `${height}%` : '200px'};
    border-radius: ${theme.spacing.mediumLarge};

    color: ${theme.palette.default};
    font-size: ${theme.fontSizes.medium};
    background-color: ${theme.palette.bgPrimary};
    padding: ${pv} ${ph};

    @media (max-width: ${theme.breakpoints.tablet}){
      width: 500px;
      height: 175px;
      padding: ${theme.spacing.medium} ${theme.spacing.large};
      ${direction === 'row' && 'height: 100px;'}
    }

    @media (max-width: ${theme.breakpoints.mobile}){
      width: 350px;
      height: 100px;
      padding: ${pv} ${theme.spacing.mediumLarge}
    }

  `};
`;

const CardContent = styled.div<
  Pick<CardContentProps, 'direction' | 'justify' | 'align' | 'ph' | 'pv' | 'pl'>
>`
  display: flex;
  ${({ direction, justify, align, ph = 0, pv = 0, pl = 0 }) => `
  flex-direction: ${direction};
  justify-content: ${justify};
  align-items: ${align}; 

  padding: ${pv} ${ph};
  padding-left: ${pl};
  `}
`;

export const CardContainer = styled.div`
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `
    gap: ${theme.spacing.superLarge};
    @media (max-width: ${theme.breakpoints.tablet}){
        gap: ${theme.spacing.medium};
      }
    @media (max-width: ${theme.breakpoints.mobile}){
        display: none;
      }
  `}
`;

const { Title } = Typography;
const InfoCardTitle = styled(Title)`
  ${({ theme }) => `
    &&& {
      color: ${theme.palette.primary};
      margin-bottom: ${theme.spacing.extraSmall}; 
      @media (max-width: ${theme.breakpoints.mobile}){
          font-size: ${theme.fontSizes.mediumSmall}
        }
    }
  `}
`;

const InfoCardText = styled(StyledParagraph)`
  ${({ theme }) => `
    &&&{
      font-size: ${theme.fontSizes.mediumSmall};
    }
  `}
`;

interface CardContentProps {
  title?: string;
  content: string;
  direction?: React.CSSProperties['flexDirection'];
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  ph?: string | 0;
  pv?: string | 0;
  pl?: string | 0;
}

export const InfoCardContent: FC<CardContentProps> = ({
  title,
  content,
  direction = 'row',
  justify = 'flex-start',
  align = 'flex-start',
  ph,
  pv,
  pl,
}) => (
  <CardContent
    direction={direction}
    justify={justify}
    align={align}
    ph={ph}
    pv={pv}
    pl={pl}>
    {title && <InfoCardTitle level={3}>{title}</InfoCardTitle>}
    <InfoCardText mb={0}>{content}</InfoCardText>
  </CardContent>
);

interface CardProps {
  Icon: FC;
  direction?: React.CSSProperties['flexDirection'];
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  height?: number;
  width?: number;
  ph?: string | 0;
  pv?: string | 0;
  children: any;
}
export const InfoCard: FC<CardProps> = ({
  Icon,
  direction = 'row',
  justify = 'flex-start',
  align = 'flex-start',
  height,
  width,
  ph,
  pv,
  children,
}) => {
  return (
    <InfoCardWrapper
      direction={direction}
      justify={justify}
      align={align}
      width={width}
      height={height}
      ph={ph}
      pv={pv}>
      <Icon />
      {children}
    </InfoCardWrapper>
  );
};
