import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Alert, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { INamedLocation } from '@reifyhealth/microsite-components';

export const AutoCompleteWrapper = styled.div`
  border: ${({ theme }) => theme.siteLookupAutocomplete.wrapper.border};
  border-color: ${({ theme }) =>
    theme.siteLookupAutocomplete.wrapper.borderColor};
  background-color: ${({ theme }) =>
    theme.siteLookupAutocomplete.wrapper.backgroundColor};
  padding: ${({ theme }) => theme.siteLookupAutocomplete.wrapper.padding};
`;

export const SearchIconWrapper = styled.span`
  color: ${({ theme }) => theme.siteLookupAutocomplete.icon.color};
  & .search-icon {
    font-size: ${({ theme }) => theme.siteLookupAutocomplete.icon.fontSize};
  }
`;

export interface IReifyPlaceSearchInputProps {
  /**
   * Current user location
   */
  currentLocation: INamedLocation;
  /**
   * Callback function to execute on location change
   */
  onPlaceChanged?: (location: INamedLocation) => void;
  /**
   * Label text to show in input
   */
  labelText: string;
  /**
   * Custom className(s) to attach
   */
  className?: string | string[];
}

export const ReifyPlaceSearchInput = ({
  currentLocation,
  onPlaceChanged,
  labelText,
  className,
}: IReifyPlaceSearchInputProps) => {
  const { t } = useTranslation();
  const [locationNotFound, setLocationNotFound] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState(
    currentLocation?.formattedAddress,
  );

  useEffect(
    () => setFormattedAddress(currentLocation.formattedAddress),
    [currentLocation.formattedAddress],
  );

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const onAutoCompleteLoad = useCallback(function onAutoCompleteLoad(
    autocomplete: google.maps.places.Autocomplete,
  ) {
    autocompleteRef.current = autocomplete;
  },
  []);
  const onAutoCompleteUnmount = useCallback(function onAutoCompleteUnmount() {
    autocompleteRef.current = null;
  }, []);

  const placeChangedHandler = useCallback(() => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      if (onPlaceChanged) {
        onPlaceChanged({
          latlng: place.geometry.location.toJSON(),
          formattedAddress: place.formatted_address || '',
        });
      }
      setLocationNotFound(false);
      if (place.formatted_address) {
        setFormattedAddress(place.formatted_address);
      }
    } else {
      setLocationNotFound(true);
      setFormattedAddress(place?.name || '');
    }
  }, [onPlaceChanged]);

  return (
    <AutoCompleteWrapper
      className={`msc-site-lookup-autocomplete__wrapper ${
        typeof className === 'string' ? className : className?.join(' ')
      }`}>
      <Autocomplete
        onLoad={onAutoCompleteLoad}
        onPlaceChanged={placeChangedHandler}
        onUnmount={onAutoCompleteUnmount}
        fields={['place_id', 'geometry', 'formatted_address']}
        className="msc-site-lookup-autocomplete__autocomplete"
        options={{ types: ['geocode'] }}>
        <Input
          placeholder={labelText}
          id="site-search"
          value={formattedAddress}
          onChange={(evt) => setFormattedAddress(evt.target.value)}
          aria-label={labelText}
          size="large"
          className="msc-site-lookup-autocomplete__input"
          prefix={
            <SearchIconWrapper className="msc-site-lookup-autocomplete__icon-wrapper">
              <FontAwesomeIcon
                className="msc-site-lookup-autocomplete__search-icon"
                icon={faMapMarkerAlt}
              />
            </SearchIconWrapper>
          }
        />
      </Autocomplete>
      {locationNotFound && (
        <Alert
          message={t('locations.unableToLocateAddress')}
          closable
          className="msc-site-lookup-autocomplete__alert"
          type="warning"
          style={{ width: '100%', margin: '0.5rem 0rem' }}
        />
      )}
    </AutoCompleteWrapper>
  );
};
