import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import config from '../config';
import { getBrowserInfo } from '../utils/getBrowserInfo';
import { UtmParametersBody } from './types';

const api = axios.create({
  baseURL: 'https://api-eu.mixpanel.com',
  headers: {
    accept: 'text/plain',
    // needed to prevent a preflight request that was causing CORS errors
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const trackAnonymousEvent = async (
  eventName: string,
  properties?: any,
) => {
  const queryParams = new URLSearchParams(window.location.search);
  // get UTM params from query params
  const utmCodes = {
    utm_source: queryParams.get('utm_source') || undefined,
    utm_medium: queryParams.get('utm_medium') || undefined,
    utm_campaign: queryParams.get('utm_campaign') || undefined,
    utm_content: queryParams.get('utm_content') || undefined,
    utm_term: queryParams.get('utm_term') || undefined,
  };

  const params = new URLSearchParams();
  params.append(
    'data',
    JSON.stringify({
      event: eventName,
      properties: {
        ...properties,
        token: config.mixpanelId,
        distinct_id: '',
        $insert_id: uuidv4(),
        time: Date.now(),
        eventType: 'anonymous',
        ...utmCodes,
        ...getBrowserInfo(),
      },
    }),
  );

  const response = await api.post('/track', params);

  return response.data;
};

/**
 * Retrieve all UTM parameters
 */
export const getUtmParameters = () => {
  const params = new URLSearchParams(window.location.search);

  const utmParams: UtmParametersBody = {
    utm_parameters: Array.from(params).reduce((acc, param) => {
      const [k, val] = param;
      if (val === '') {
        return acc;
      }
      const key = k.replace(/^utm/i, 'utm');
      if (key.startsWith('utm')) {
        return acc[key]
          ? { ...acc, [key]: [...acc[key], val] }
          : { ...acc, [key]: [val] };
      }
      return acc;
    }, Object()),
  };
  return utmParams;
};

export const trackEvent = (eventName: string, properties?: any) => {
  if (window.Cookiebot?.consent?.statistics) {
    try {
      mixpanel?.track(eventName, properties);
    } catch (e) {
      //
    }
  }
  trackAnonymousEvent(eventName, properties || {});
};
