/* eslint no-nested-ternary: 0 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import Ajv from 'ajv';
import { AjvError } from '@rjsf/core';
import { omit } from 'lodash';
import {
  OrchestratorPager,
  OrchestratorPage,
  validators,
  formUtils,
  FormSchema,
  Locale,
  useOrchestrator,
  PagerFooter,
  useSubmitPrescreenData,
  useSubmitReferral,
  V1Site,
  analytics,
} from '@reifyhealth/microsite-components';

import { Button, Card } from 'antd';
import jsonLogic, { RulesLogic } from 'json-logic-js';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import config from '../../config';

import { constructPrescreenDataPayload } from '../../utils/constructPrescreenDataPayload';

import { ModalHeader } from '../Modal/Modal';
import { PersonalInfoFormData } from '../../core/types';
import { PersonalInfoForm } from './PersonalInfoForm';
import { PreScreenForm } from './PreScreenForm';
import { NotQualifiedPage, QualifiedPage } from './FinalPages';

import { Notification } from '../Notification/Notification';
import { CustomCheckboxWidget } from '../Widgets/CheckboxWidget';
import { CustomPhoneNumberWidget } from '../Widgets/PhoneNumberWidget';
import { translate } from '../../utils/i18n';
import { convertFormDataToReferralRequestBodyFormat } from '../../utils/convertFormDataToReferralRequestBodyFormat';
import { getBasicInfoFormSchema } from '../../data/form/basicInfoSchema';
import { getPreScreenQuestionsFormSchema } from '../../data/form/preScreenSchema';
import { useLocaleCode } from '../../core/hooks';
import { getUtmParameters, trackEvent } from '../../core/analytics';
import mySessionStorage from '../../utils/sessionStoragePolyfill';

const ajv = new Ajv();
ajv.addFormat('phone', validators.phone);
ajv.addFormat('email', validators.email);

const defaultFormProps = {
  noHtml5Validate: true,
  showErrorList: false,
  transformErrors: formUtils.transformErrors,
  customFormats: validators,
  liveValidate: false,
};

export const parseUTMValues = (values: Array<string[]>) => {
  if (!values.length) {
    return undefined;
  }
  return values.reduce((acc, [key, val]) => {
    if (key.startsWith('utm') || key.startsWith('UTM')) {
      return `${key}=${val}${acc !== '' ? '&' : ''}${acc}`;
    }
    return acc;
  }, '');
};

function transformErrors(errors: AjvError[]) {
  return errors.map((error) => {
    if (error.name === 'required') {
      if (error.property === '.email') {
        return {
          ...error,
          message: translate('form.errorMessage.emailOrPhoneRequired'),
        };
      }
      if (error.property === '.phone') {
        return {
          ...error,
          message: translate('form.errorMessage.emailOrPhoneRequired'),
        };
      }
      return {
        ...error,
        message: translate('form.errorMessage.fieldRequired'),
      };
    }
    if (error.name === 'format') {
      if (error.property === '.email') {
        return {
          ...error,
          message: translate('form.errorMessage.incorrectEmailFormat'),
        };
      }
      if (error.property === '.phone') {
        return {
          ...error,
          message: translate('form.errorMessage.invalidPhone'),
        };
      }
      return {
        ...error,
        message: translate('form.errorMessage.incorrectFieldFormat'),
      };
    }

    return error;
  });
}

const prescreenWidgets = {
  CustomCheckbox: CustomCheckboxWidget,
};
const basicInfoWidgets = {
  PhoneNumberWidget: CustomPhoneNumberWidget,
};

export interface AtlasFormsProps {
  siteInfo: V1Site;
  onFinish: () => void;
}

export const AtlasForms = ({ onFinish, siteInfo }: AtlasFormsProps) => {
  const { t, i18n } = useTranslation();
  const [params] = useSearchParams();

  let campaignCode = parseUTMValues(Array.from(params.entries()));
  if (campaignCode === '') {
    campaignCode = undefined;
  }

  const { country } = siteInfo;

  const englishText = i18n.getFixedT('en-US');
  const englishSchemas = getPreScreenQuestionsFormSchema(englishText);
  const { uiSchema: basicInfoUiSchema, mainSchema: basicInfoSchema } =
    getBasicInfoFormSchema(t);
  const { uiSchema: prescreenUiSchema, mainSchema: prescreenSchema } =
    getPreScreenQuestionsFormSchema(t, country);

  const { activePage, nextPage, previousPage } = useOrchestrator();
  const [preScreenFormData, setPrescreenFormData] = useState({});
  const [personalInfoFormData, setPersonalInfoFormData] = useState({
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
  });

  const { submitPrescreenData, isSubmitPrescreenDataLoading } =
    useSubmitPrescreenData();
  const { submitReferral, isSubmitReferralLoading } = useSubmitReferral();

  const [preScreenQuestionsData, setPreScreenQuestionsData] = useState<
    {
      linkId: string;
      question: string;
      answer: string;
      type: string;
    }[]
  >([]);

  const fullLanguageCode = useLocaleCode()[1] as Locale;

  const [liveValidate, setLiveValidate] = useState(false);
  const [title, setTitle] = useState(
    t('form.modalHeader.seeIfYouPreQualify') as string,
  );
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const submitBtnRef2 = useRef<HTMLButtonElement | null>(null);

  const [consentText, setConsentText] = useState('');

  useEffect(() => {
    if (activePage) {
      if (activePage.pageId === 'prescreenForm')
        setConsentText(t('form.footerText.infoSharingPreScreen'));
      else if (activePage.pageId === 'basicInfo')
        setConsentText(t('form.footerText.infoSharingPersonalInfo'));
      else setConsentText('');
    }
  }, [activePage]);

  const getEligibilityQuestions = () => {
    return Object.entries(preScreenFormData).filter(([key, _]) => {
      return (
        typeof (prescreenSchema.properties as any)[key].eligibilityLogic !==
        'undefined'
      );
    });
  };

  const evaluateQuestions = (): any => {
    // maps question key to true or false depending on eligibility
    const evaluationMap: any = {};

    getEligibilityQuestions().forEach(([key, value]) => {
      evaluationMap[key] = jsonLogic.apply(
        (prescreenSchema.properties as any)[key].eligibilityLogic,
        value,
      );
    });

    return evaluationMap;
  };

  const isEligible = (): boolean => {
    return !Object.values(evaluateQuestions()).includes(false);
  };

  const constructEligibilityLogic = () => {
    const evaluationMap: any = {};

    getEligibilityQuestions().forEach(([key, value]) => {
      evaluationMap[key] = (prescreenSchema.properties as any)[
        key
      ].eligibilityLogic;
    });

    // this is cursed
    // replaces `var=''` string with the corresponding UUID for a key
    // provides consistency with a generic JSONLogic object
    // equivalent to how we evaluate the questions independently
    const andList = Object.entries(evaluationMap).map(([key, value]) => {
      return JSON.parse(
        JSON.stringify(value, (k, v) =>
          k === 'var' && v === ''
            ? (prescreenSchema.properties as any)[key].uuid
            : v,
        ),
      );
    });

    return { and: andList };
  };

  const preScreenChecker = (): string => {
    if (isEligible()) {
      setTitle(t('form.modalHeader.eligible'));
      return 'basicInfo';
    }
    setTitle(t('form.modalHeader.ineligible'));
    trackEvent('form-submitted', { preScreenResult: 'fail' });
    return 'preScreenFailure';
  };

  const onOkClick = () => {
    if (activePage.pageId === 'prescreenForm') {
      submitBtnRef.current?.click();
      setLiveValidate(true);
      trackEvent('form-flow-stage-changed', {
        currentStep: 'pre-screening',
        nextStep: 'basic-info',
      });
    } else if (activePage.pageId === 'basicInfo') {
      submitBtnRef2.current?.click();
      setLiveValidate(true);
    }
  };

  return (
    <>
      <ModalHeader onClose={onFinish} title={title} />
      <Card
        style={{
          padding: '1rem',
          overflow: 'scroll',
        }}>
        <OrchestratorPager activePage={activePage?.pageId}>
          <OrchestratorPage pageId="prescreenForm" next={preScreenChecker}>
            <Notification closable={false} message={t('form.message.msg1')} />
            <PreScreenForm
              {...defaultFormProps}
              widgets={prescreenWidgets}
              formData={{ ...preScreenFormData }}
              schema={prescreenSchema as FormSchema}
              uiSchema={prescreenUiSchema}
              onChange={(e: any) => {
                setPrescreenFormData(e.formData);
                const validate = ajv.compile(e.schema);
                const valid = validate(e.formData);
                if (valid) {
                  setSubmitButtonDisabled(false);
                } else setSubmitButtonDisabled(true);
              }}
              liveValidate={liveValidate}
              onSubmit={({ formData }) => {
                const { properties } = englishSchemas.mainSchema;

                const eligibilityMap = evaluateQuestions();

                // Since consent checkbox input is part of formData and should not be included
                // in questions array in request body we omit that from properties.
                // Consent is handled elsewhere
                const questions = Object.keys(
                  omit(properties, 'consentCheckbox'),
                ).map((k: any) => {
                  const props: any = properties as any;

                  // complies with this FHIR standard type
                  // https://www.hl7.org/fhir/valueset-item-type.html
                  const customTypeMap: { [key: string]: string } = {
                    '#/definitions/yesNoUnknown': 'choice',
                  };

                  const ref: string = props[k].$ref || '';

                  return {
                    linkId: props[k].uuid as string,
                    question: props[k].title as string,
                    answer: String(formData[k]) || '',
                    type: (customTypeMap[ref] || props[k].type) as string,
                    passing: eligibilityMap[k],
                    sensitive: props[k].containsSensitiveInfo,
                    eligibilityLogic: props[k].eligibilityLogic,
                  };
                });

                const questionsWithEligibility = questions.filter(
                  (question) =>
                    typeof question.eligibilityLogic !== 'undefined',
                );
                setPreScreenQuestionsData(questions);

                const subjectId = mySessionStorage.getItem(
                  'subjectId',
                ) as string;

                submitPrescreenData(
                  constructPrescreenDataPayload(
                    config.micrositeId,
                    config.trialId,
                    isEligible(),
                    siteInfo.siteId,
                    siteInfo.sponsorSiteId,
                    fullLanguageCode,
                    constructEligibilityLogic(),
                    questionsWithEligibility,
                    subjectId,
                    getUtmParameters()?.utm_parameters,
                  ),
                );

                nextPage();

                setLiveValidate(false);
                setSubmitButtonDisabled(true);
              }}>
              <Button htmlType="submit" hidden ref={submitBtnRef} />
            </PreScreenForm>
          </OrchestratorPage>

          <OrchestratorPage pageId="basicInfo" next="dataSubmitted">
            <Notification closable={false} message={t('form.message.msg2')} />
            <PersonalInfoForm
              {...defaultFormProps}
              formData={{
                ...(personalInfoFormData as unknown as PersonalInfoFormData),
              }}
              schema={basicInfoSchema as FormSchema}
              widgets={basicInfoWidgets}
              uiSchema={basicInfoUiSchema}
              transformErrors={transformErrors}
              liveValidate={liveValidate}
              onChange={(e: any) => {
                setPersonalInfoFormData(e.formData);
                const validate = ajv.compile(e.schema);
                const valid = validate(e.formData);
                if (valid) {
                  setSubmitButtonDisabled(false);
                } else setSubmitButtonDisabled(true);
                setLiveValidate(true);
              }}
              onSubmit={({ formData }) => {
                const { sponsorSiteId, siteId } = siteInfo;
                const { micrositeId, trialId } = config;
                const subjectId = mySessionStorage.getItem(
                  'subjectId',
                ) as string;
                const formDataObj = {
                  formData,
                  preScreenQuestionsData,
                  consentText:
                    englishSchemas.mainSchema.properties.consentCheckbox.title,
                  sponsorSiteId,
                  trialId,
                  campaignCode,
                  subjectId,
                };
                const referral =
                  convertFormDataToReferralRequestBodyFormat(formDataObj);

                submitReferral(
                  {
                    referral,
                    siteId,
                    micrositeId,
                  },
                  {
                    onSuccess: () => {
                      nextPage();
                      setTitle(t('form.modalHeader.success'));
                      trackEvent('form-submitted', {
                        submissionStatus: 'succeeded',
                        preScreenResult: 'pass',
                      });
                    },
                  },
                );
              }}>
              <Button htmlType="submit" hidden ref={submitBtnRef2} />
            </PersonalInfoForm>
          </OrchestratorPage>

          <OrchestratorPage pageId="preScreenFailure" isFinal>
            <NotQualifiedPage />
          </OrchestratorPage>
          <OrchestratorPage pageId="dataSubmitted" isFinal>
            <QualifiedPage />
          </OrchestratorPage>
        </OrchestratorPager>
      </Card>
      <div id="pager-footer-wrapper">
        <PagerFooter
          okText={
            activePage?.pageId === 'prescreenForm'
              ? t('form.next')
              : activePage?.isFinal
              ? t('form.done')
              : isSubmitReferralLoading
              ? t('form.submitting')
              : t('form.submit')
          }
          onBackClicked={previousPage}
          onOkClick={activePage?.isFinal ? onFinish : onOkClick}
          okButtonProps={{
            disabled:
              activePage?.pageId === 'preScreenFailure'
                ? false
                : isSubmitReferralLoading
                ? true
                : submitButtonDisabled,
          }}
          onCancelClicked={onFinish}
          showCancelButton={!activePage?.isFinal}
          cancelButtonText={t('form.cancel')}
          showBackButton={false}
          message={consentText}
          okButtonMixpanelData={`Form_OK_Button_Click_${activePage?.pageId}`}
          cancelButtonMixpanelData={`Form_Cancel_Button_Click_${activePage?.pageId}`}
        />
      </div>
    </>
  );
};
