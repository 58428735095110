import { Checkbox } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    gap: ${theme.spacing.medium};
    padding: ${theme.spacing.medium};
    background-color: ${theme.palette.checkbox};
    a {
      font-size: ${theme.fontSizes.mediumSmall};
      font-weight: bold;
      width: max-content;
      color: ${theme.palette.dawn};
    }
    span {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      color: black;
    }
  `}
`;
export const CustomCheckboxWidget = (props: any) => {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <CheckboxWrapper>
      <a
        href={t('footer.navItems.privacyPolicy.url')}
        data-mixpanelid="Form_Privacy_Policy_Link"
        style={{ width: '100%' }}
        target="_blank"
        rel="noreferrer">
        {t('form.checkbox.privacyPolicy')}
      </a>
      <Checkbox
        id="root_consentCheckbox"
        name="root_consentCheckbox"
        data-testid="root_consentCheckbox"
        checked={value || false}
        // eslint-disable-next-line react/destructuring-assignment
        onClick={() => props.onChange(!props.value)}>
        <Trans
          i18nKey="form.preScreenQuestions.consentCheckbox"
          t={t}
          components={{
            mailto: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href="mailto:privacyofficer@onestudyteam.com" id="emailLink" />
            ),
          }}
        />
      </Checkbox>
    </CheckboxWrapper>
  );
};
