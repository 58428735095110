import { defaultTheme } from '@reifyhealth/microsite-components';

export const customTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    dawn: '#922D61',
    primary: '#4FA829',
    primaryColor: '#000',
    white: '#FFFFFF',
    black: '#000000',
    default: '#696f83',
    primaryLight: '',
    secondary: '#389E0D',
    secondaryLight: '',
    finalPages: '#000000a6',
    consentText: '#757575',
    privacyPolicy: '#922c62',
    sectionBackgroundPrimary:
      'linear-gradient(271.27deg, rgb(0, 38, 50) 37.5%, rgb(1, 96, 126) 70.25%) rgb(1, 96, 126);',
    sectionBackgroundSecondary: '#F8F8F8',
    sectionBackground: '#f7f7f7',
    checkbox: '#f0f0f0',
    buttonPrimary: '#389E0D',
    buttonPrimaryLight: '#4db91e',
    buttonTextPrimary: '#FFFFFF',
    buttonSecondary: '',
    buttonSecondaryLight: '',
    buttonTextSecondary: '',
    bgPrimary: '#F4F2EF',
    navLinkMobile: '#F1F1F1',
    navBorder: '#3EA7AD',
  },
  fontSizes: {
    small: '0.5rem',
    mediumSmall: '1rem',
    medium: '1.25rem',
    mediumLarge: '1.5rem',
    mediumLarge2: '2rem',
    large: '2.5rem',
    subtitle: '3rem',
    subtitleLarge: '3.5rem',
    title: '4rem',
  },
  breakpoints: {
    mobile: '750px',
    tablet: '1000px',
    desktop: '1280px',
    extraWide: '1920px',
  },
  spacing: {
    extraSmall: '0.3rem',
    small: '0.75rem',
    medium: '1rem',
    mediumLarge: '1.5rem',
    large: '2rem',
    veryLarge: '3rem',
    superLarge: '6rem',
    superLarge2: '10rem',
    extraLarge: '12rem',
    extraLarge2: '15rem',
    ultraLarge: '18rem',
  },
  fonts: {
    family: 'Arial',
    fontWeight400: '400',
    fontWeight500: '500',
    lineHeightLarge: '1.5',
    lineHeight: '1.2',
  },
};
