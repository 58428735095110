import React from 'react';
import { STForm, STFormProps } from '@reifyhealth/microsite-components';

import { PersonalInfoFormData } from '../../core/types';

export type PersonalInfoFormProps = STFormProps<PersonalInfoFormData>;

export const PersonalInfoForm = (props: PersonalInfoFormProps) => (
  <STForm {...props} />
);
