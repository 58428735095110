import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  color: #00000073;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  height: 1.25rem;
  width: 1.25rem;
`;
export const CloseButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button
    type="button"
    title="close"
    className="delete"
    aria-label="close"
    {...props}
  />
);
