import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';
import { CustomThemeProvider } from '@reifyhealth/microsite-components';
import { customTheme } from './theme';
import Home from './pages/Home';
import HeaderAndFooter from './components/HeaderAndFooter/HeaderAndFooter';
import GlobalStyle from './globalStyles';
import { supportedLanguages } from './utils/i18n';
import config from './config';
import ErrorBoundary from './ErrorBoundary';

const MainContainer = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
    font-family: ${theme.fonts.family};
    line-height: ${theme.fonts.lineHeight};
  `}
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    window.addEventListener(
      'CookiebotOnAccept',
      () => {
        mixpanel.init(config.mixpanelId, { debug: true });
        if (
          config.env &&
          config.env !== 'development' &&
          window.Cookiebot?.consent?.statistics
        ) {
          datadogRum.init({
            applicationId: '58377130-554a-470f-bf47-408b1a0c679a',
            clientToken: 'pub87015105df1e44ccdaa4f2a1707054d0',
            site: 'datadoghq.com',
            service: 'atlas-microsite',
            env: config.env,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sampleRate: 100,
            premiumSampleRate: 100,
            trackInteractions: true,
          });
        }
      },
      false,
    );
  }, []);

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <CustomThemeProvider theme={customTheme}>
          <React.Suspense fallback="loading...">
            <QueryClientProvider client={queryClient}>
              <GlobalStyle />
              <MainContainer>
                <Router>
                  <Routes>
                    <Route path="/" element={<HeaderAndFooter />}>
                      <Route index element={<Home />} />
                      {supportedLanguages &&
                        supportedLanguages.map((lng) => (
                          <Route key={lng} path={lng} element={<Home />} />
                        ))}
                    </Route>
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </Router>
              </MainContainer>
            </QueryClientProvider>
          </React.Suspense>
        </CustomThemeProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;
