import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Notification } from '../Notification/Notification';

const Paragraph = styled.p`
  ${({ theme }) => `
    color: ${theme.palette.finalPages};
    font-size: ${theme.fontSizes.mediumLarge};
    font-weight: ${theme.fonts.fontWeight};
    @media (max-width: ${theme.breakpoints.tablet}) {
      font-size: ${theme.fontSizes.medium};
    }
`}
`;

export const NotQualifiedPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Notification closable={false}>
      <Paragraph>
        {t('form.notification.thankYou')} {t('form.notification.ineligible')}
      </Paragraph>
    </Notification>
  );
};

export const QualifiedPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Notification closable={false} bgColor="#F6FFED">
      <Paragraph>
        <strong>{t('form.notification.thankYou')}</strong>
        {t('form.notification.eligiblePart1')}{' '}
        <a
          href="https://clinicaltrials.gov/ct2/show/NCT05097287"
          data-mixpanelid="Eligible_Info_Screen_Link"
          target="_blank"
          rel="noreferrer">
          clinicaltrials.gov
        </a>{' '}
        {t('form.notification.eligiblePart2')}
      </Paragraph>
    </Notification>
  );
};
