import React, { FC, useEffect, useState } from 'react';
import '../../utils/i18n';
import { analytics } from '@reifyhealth/microsite-components';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useLocaleCode } from '../../core/hooks';
import { Header } from './Header';
import { Footer } from './Footer';
import config from '../../config';

const HeaderAndFooter: FC = () => {
  const { t } = useTranslation();
  const [code, fullLanguageCode] = useLocaleCode();
  const PolicyUrl = t('footer.navItems.privacyPolicy.url', {
    defaultValue: 'https://www.onestudyteam.com/privacy-policy',
  });
  useEffect(() => {
    analytics.pushToDataLayer({
      currentLocale: fullLanguageCode,
      currentLanguage: code,
    });
  }, [fullLanguageCode]);

  const [tab, setTab] = useState<string>('locations');

  return (
    <>
      <Header
        title={t('common.pageTitle')}
        navItems={[
          {
            id: 1,
            text: t('common.findLocationNearText'),
            href: '#locations-and-info',
          },
          {
            id: 2,
            text: t('common.faq'),
            href: '#faq',
          },
        ]}
        setTab={setTab}
      />
      <Helmet>
        <html lang={fullLanguageCode} />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-culture={code.slice(0, 2)}
          data-cbid={config.cookieBotNonUsId}
          // NOTE: this uses the IP address of the user to detect this reason
          // US users will not see the OUS banner, and the VPN will also affect this
          data-georegions={`{'region':'US','cbid':'${config.cookieBotUsId}'}`}
          type="text/javascript"
          async
        />
      </Helmet>
      <Outlet context={{ tab, setTab }} />
      <Footer
        title={t('common.pageTitle')}
        navItems={[
          {
            id: 1,
            text: t('footer.navItems.privacyPolicy.title'),
            href: PolicyUrl,
          },
        ]}
      />
    </>
  );
};

type TContext = {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
};

export function useTab() {
  return useOutletContext<TContext>();
}

export default HeaderAndFooter;
